import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { displayDate } from "../utils/utils";
import { useArchiveDate } from "../ArchiveDateContext";

type ContinueGameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  onStartNew: () => void;
};

const ContinueGameModal: React.FC<ContinueGameModalProps> = ({
  isOpen,
  onClose,
  onContinue,
  onStartNew,
}) => {
  const { archiveDate } = useArchiveDate();
  const isYesterday = (date: Date) => {
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Continue Game</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            {archiveDate
              ? isYesterday(new Date(archiveDate))
                ? "Continue game from yesterday?"
                : `Continue game from ${displayDate(new Date(archiveDate))}?`
              : "Continue game in progress?"}{" "}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" borderColor="gray.700" onClick={onContinue}>
            Yes, Continue
          </Button>
          <Button colorScheme="orange" ml={3} onClick={onStartNew}>
            No, Exit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContinueGameModal;
