import React, { useState, useEffect, CSSProperties } from "react";
import { GridItem, Flex, Icon } from "@chakra-ui/react";
import ReactTextTransition, { presets } from "react-text-transition";
import "./movetag.css";
import DefinitionDrawer from "./DefinitionDrawer";
import { LuHeart } from "react-icons/lu";
import { determineTransformation, getColorFromType } from "../../utils/utils";

const FONT_SIZES_TO_MAX_LETTERS = {
  19: 5,
  17: 6,
  15: 7,
  13: 8,
  10: Infinity,
};

function calculateFontSize(word?: string) {
  if (!word || word.length === 0) {
    return 10;
  }

  const letters = word.length;
  for (const [fontSize, maxLetters] of Object.entries(FONT_SIZES_TO_MAX_LETTERS)
    .map(([key, val]) => [parseInt(key, 10), val])
    .sort(([keyA], [keyB]) => keyB - keyA)) {
    if (letters <= maxLetters) {
      return fontSize;
    }
  }
}

const CraftWordTag: React.FC<{
  children: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  icon?: React.ReactElement;
  fontSize?: string;
  cursor?: string;
  onClick?: () => void;
  hideWord?: boolean;
  style?: CSSProperties;
}> = ({ children, backgroundColor, textColor, icon, hideWord, style }) => {
  return (
    <Flex
      h="32px"
      w="72px"
      align="center"
      justify="center"
      fontWeight="semibold"
      background={backgroundColor}
      color={textColor}
      style={style}
    >
      {icon && icon}
      {!hideWord && children}
    </Flex>
  );
};

const MoveTag: React.FC<{
  word: string;
  comparisonWord: string;
  direction: "forward" | "backward" | "both";
  shouldAnimate?: boolean;
  colorScheme?: any;
  isWinningMove?: boolean;
  onAnimationEnd?: () => void;
  isRare?: boolean;
  backgroundColor?: string;
  textColor?: string;
  icon?: React.ReactElement;
  definitions?: boolean;
  hideWord?: boolean;
  useTextTransition?: boolean;
  style?: CSSProperties;
}> = ({
  word,
  comparisonWord,
  direction,
  shouldAnimate = true,
  isWinningMove,
  onAnimationEnd,
  definitions,
  useTextTransition = true,
  ...props
}) => {
  const shouldAnimateFromPrevious =
    shouldAnimate && comparisonWord && comparisonWord !== word;
  const [showFinalState, setShowFinalState] = useState(!shouldAnimate);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFinalState(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [comparisonWord, word]);

  const getBackgroundColor = () => {
    if (!showFinalState) return "grayBrand.300";

    let transformationType;
    if (direction === "forward" || direction === "both") {
      transformationType = determineTransformation(comparisonWord, word);
    } else {
      // For backward moves, we compare the current word to the comparison word
      transformationType = determineTransformation(word, comparisonWord);
    }

    const color = getColorFromType(transformationType);
    return color;
  };

  const backgroundColor = getBackgroundColor();
  const textColor = showFinalState ? "grayBrand.50" : "grayBrand.900";

  const [victoryAnimation, setVictoryAnimation] = useState(false);
  useEffect(() => {
    if (isWinningMove) {
      const timeout = setTimeout(() => {
        setVictoryAnimation(true);
      }, 750);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isWinningMove]);

  const displayWord = showFinalState ? word : comparisonWord;

  return (
    <DefinedWordBlock
      word={word}
      {...props}
      backgroundColor={backgroundColor}
      textColor={textColor}
      style={{
        transition: "background-color 0.5s ease-out, color 0.1s ease-out",
      }}
    >
      {displayWord ? (
        <div style={{ textAlign: "center" }}>
          {displayWord.split("").map((letter: string, index: number) => (
            <div key={index} style={{ display: "inline-block" }}>
              {victoryAnimation ? (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    animationDelay: `${index * 100}ms, 600ms`,
                  }}
                  className="animate-letter"
                  onAnimationEnd={
                    index === word.length - 1 ? onAnimationEnd : undefined
                  }
                >
                  {letter}
                </div>
              ) : useTextTransition ? (
                <ReactTextTransition
                  springConfig={
                    shouldAnimateFromPrevious && displayWord === word
                      ? presets.stiff
                      : {
                          duration: 0,
                          easing: (v) => v,
                        }
                  }
                  translateValue={"45%"}
                  inline
                >
                  {letter}
                </ReactTextTransition>
              ) : (
                <span>{letter}</span>
              )}
            </div>
          ))}
        </div>
      ) : (
        " "
      )}
    </DefinedWordBlock>
  );
};

export default MoveTag;

type WordBlockProps = {
  word: string;
  isRare?: boolean;
  definitions?: boolean;
  textColor?: string;
  backgroundColor?: string;
  icon?: React.ReactElement;
  hideWord?: boolean;
  style?: CSSProperties;
};

export function DefinedWordBlock({
  word,
  definitions,
  children,
  isRare,
  textColor = "grayBrand.50",
  backgroundColor = "grayBrand.500",
  ...props
}: {
  children: React.ReactNode;
} & WordBlockProps) {
  const fontSize = calculateFontSize(word) + "px";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClick = () => {
    if (definitions) {
      setIsDrawerOpen(true);
    }
  };

  const interactionProps = word
    ? {
        onClick: () => {
          setIsDrawerOpen(true);
        },
        cursor: "pointer",
      }
    : {};

  return (
    <GridItem
      colSpan={1}
      rowSpan={1}
      position="relative"
      onClick={handleClick}
      cursor={definitions ? "pointer" : "auto"}
      {...interactionProps}
    >
      <CraftWordTag
        fontSize={fontSize}
        textColor={textColor}
        backgroundColor={backgroundColor}
        {...props}
      >
        {children}
      </CraftWordTag>
      <DefinitionDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        word={word}
        isRare={isRare}
      />
      {isRare && (
        <Flex
          alignItems="center"
          justifyContent="center"
          w="17px"
          h="17px"
          fontSize="12px"
          bg="grayBrand.500"
          color="grayBrand.50"
          position="absolute"
          top="-2px"
          right="-2px"
          borderRadius="50%"
          onClick={() => {
            setIsDrawerOpen(!isDrawerOpen);
          }}
          cursor="pointer"
        >
          <Icon as={LuHeart} />
        </Flex>
      )}
    </GridItem>
  );
}

export function StaticWordBlock({ word, ...props }: WordBlockProps) {
  return (
    <DefinedWordBlock {...props} word={word}>
      {word}
    </DefinedWordBlock>
  );
}
