import React, { useMemo } from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Flex,
  Box,
  Text,
  VStack,
  HStack,
  Progress,
} from "@chakra-ui/react";
import type { GameDetailType } from "./types";

interface ScoreHistogram {
  [score: number]: number;
}

type PlayerStatsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  gameDetails: GameDetailType[] | undefined;
  onRareWordsDrawerOpen: () => void;
};

const PlayerStatsDrawer: React.FC<PlayerStatsDrawerProps> = ({
  isOpen,
  onClose,
  gameDetails,
  onRareWordsDrawerOpen,
}) => {
  const {
    played,
    completedGames,
    streak,
    maxStreak,
    rareWordsCount,
    scoreHistogram,
  } = useMemo(() => {
    const details = gameDetails || [];
    const completedGames = details.filter((game) => !game.gaveUp);
    const rareWords = completedGames.reduce(
      (acc, game) => acc + game.rareWordsUsed.length,
      0,
    );
    let scoreCounts: ScoreHistogram = {};

    // Populate the histogram with scores from completed games only
    completedGames.forEach(({ dayScore }) => {
      scoreCounts[dayScore] = (scoreCounts[dayScore] || 0) + 1;
    });

    // Determine the minimum and maximum scores for filling gaps
    const scores = Object.keys(scoreCounts).map(Number);
    const minScore = Math.min(...scores);
    const maxScore = Math.max(...scores);

    // Ensure all scores in the range have an entry
    for (let score = minScore; score <= maxScore; score++) {
      scoreCounts[score] = scoreCounts[score] || 0;
    }

    let currentStreak = 0,
      longestStreak = 0,
      tempStreak = completedGames.length > 0 ? 1 : 0;
    const sortedDates = completedGames
      .map((game) => new Date(game.date).getTime())
      .sort((a, b) => a - b);
    for (let i = 1; i < sortedDates.length; i++) {
      if (sortedDates[i] - sortedDates[i - 1] === 86400000) {
        // consecutive days
        tempStreak++;
      } else {
        longestStreak = Math.max(longestStreak, tempStreak);
        tempStreak = 1;
      }
    }
    longestStreak = Math.max(longestStreak, tempStreak);
    currentStreak = tempStreak;

    return {
      played: details.length,
      completedGames: completedGames.length,
      streak: currentStreak,
      maxStreak: longestStreak,
      rareWordsCount: rareWords,
      scoreHistogram: scoreCounts,
    };
  }, [gameDetails]);

  const maxScoreCount = Math.max(0, ...Object.values(scoreHistogram));

  const handleRareWordsClick = () => {
    onClose();
    setTimeout(() => {
      onRareWordsDrawerOpen();
    }, 200);
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent
          p="4"
          borderRadius="15px 15px 0px 0px"
          position="fixed"
          width="550px"
          maxHeight="80vh"
          mx="auto"
        >
          <DrawerCloseButton
            position="absolute"
            top="20px"
            right="15px"
            onClick={onClose}
          />
          <DrawerHeader
            textAlign="left"
            borderBottom="1px solid lightgray"
            pb="2"
          >
            All Time Stats
          </DrawerHeader>
          <DrawerBody overflowY="auto">
            <VStack align="stretch" mt="4">
              <HStack justify="space-around" width="100%" align="end">
                <VStack width="25%" spacing={0} align="stretch">
                  <Text fontSize="sm" textAlign="center">
                    PLAYED
                  </Text>
                  <Text fontSize="3xl" mt="auto" textAlign="center">
                    {played}
                  </Text>
                </VStack>
                <VStack width="25%" spacing={0} align="stretch">
                  <Text fontSize="sm" textAlign="center">
                    STREAK
                  </Text>
                  <Text fontSize="3xl" mt="auto" textAlign="center">
                    {streak}
                  </Text>
                </VStack>
                <VStack width="25%" spacing={0} align="stretch">
                  <Text fontSize="sm" textAlign="center">
                    MAX STREAK
                  </Text>
                  <Text fontSize="3xl" mt="auto" textAlign="center">
                    {maxStreak}
                  </Text>
                </VStack>
                <VStack width="25%" spacing={0} align="stretch">
                  <Text fontSize="sm" textAlign="center">
                    RARE 🩶 WORDS
                  </Text>
                  <Text fontSize="3xl" mt="auto" textAlign="center">
                    {rareWordsCount}
                  </Text>
                </VStack>
              </HStack>

              <Text textAlign="center" py={2} fontSize="xl">
                SCORE DISTRIBUTION
              </Text>
              <Box pt={2} pl={2} pr={4}>
                {Object.entries(scoreHistogram)
                  .sort(([a], [b]) => Number(a) - Number(b))
                  .map(([score, count]) => (
                    <Flex key={score} align="center">
                      <Text width="50px" textAlign="center" fontSize="lg">
                        {score}
                      </Text>
                      <Box width="full" position="relative" mb="2">
                        <Progress
                          value={100}
                          size="lg"
                          width={`${Math.max(
                            (count / maxScoreCount) * 100,
                            count === 0 ? 10 : 0,
                          )}%`}
                          sx={{ height: "28px" }}
                          colorScheme="orangeBrand"
                        />
                        <Text
                          position="absolute"
                          left="10px"
                          top="0"
                          color="white"
                          height="100%"
                          display="flex"
                          alignItems="center"
                          fontWeight="bold"
                        >
                          {count}
                        </Text>
                      </Box>
                    </Flex>
                  ))}
              </Box>
            </VStack>
            <Flex mt="3vh" justifyContent="center">
              <Button
                variant="outline"
                width="auto"
                onClick={handleRareWordsClick}
              >
                Rare Words Crafted 🩶
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PlayerStatsDrawer;
