import React, { useState, useEffect, useCallback } from "react";
import {
  Text,
  Box,
  Flex,
  Badge,
  IconButton,
  useToast,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import { fetchDefinition, fetchPronunciation } from "../../api/api";
import { useDefinitionContext } from "../../DefinitionContext";
import { generateDefinitionShareText } from "../../utils/utils";
import { LuShare } from "react-icons/lu";
import ReactGA from "react-ga4";

type DefinitionProps = {
  word: string;
  isRare?: boolean;
  isOpen?: boolean;
};

type DefinitionData = {
  text: string;
  partOfSpeech: string;
};

const Definition: React.FC<DefinitionProps> = ({ word, isRare, isOpen }) => {
  const [definitionData, setDefinitionData] = useState<DefinitionData[]>([]);
  const [phoneticData, setPhoneticData] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { definitionMap, setDefinitionInMap } = useDefinitionContext();
  const toast = useToast();
  const [shareText, setShareText] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 1100px)");

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return function (...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const fetchData = useCallback(async () => {
    if (!isOpen) return;

    if (definitionMap[word]) {
      // Use cached data and update state immediately without setting loading
      setDefinitionData(definitionMap[word].definition);
      setPhoneticData(definitionMap[word].pronunciation);
    } else {
      setIsLoading(true); // Set loading only when fetching new data
      try {
        const definition = await fetchDefinition(word);
        const pronunciation = await fetchPronunciation(word);
        setErrorMessage(null);

        let phonetic = null;
        if (Array.isArray(pronunciation) && pronunciation.length > 0) {
          phonetic = removeHtmlTags(pronunciation[0].raw);
          setPhoneticData(phonetic);
        }
        if (!Array.isArray(definition) || definition.length === 0) {
          setErrorMessage("Oops! We couldn't get a definition for this word.");
        } else {
          const filteredDefinitions = definition.filter(
            (def) => def.text && def.text.length > 0,
          );
          setDefinitionData(filteredDefinitions);
          setDefinitionInMap(word, {
            definition: filteredDefinitions,
            pronunciation: phonetic,
          });
        }
      } catch (error) {
        console.log("error", error);
        setErrorMessage("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  }, [word, isOpen, definitionMap, setDefinitionInMap]);

  const debouncedFetchData = useCallback(debounce(fetchData, 500), [fetchData]);

  useEffect(() => {
    if (isOpen) {
      debouncedFetchData();
    }
  }, [word]);

  const removeHtmlTags = (text: string) => {
    return text.replace(/<[^>]+>/g, "");
  };

  useEffect(() => {
    const setShareTextAsync = async () => {
      const text = await generateDefinitionShareText(word);
      setShareText(text ?? "");
    };

    if (word) {
      setShareTextAsync()
        .then((text) => {})
        .catch((error) => {
          console.error("Error setting share text:", error);
        });
    }
  }, [word]);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast({
        title: "Text copied to clipboard",
        description: "You can now paste and share it anywhere!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Could not copy text: " + String(err),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleShare = () => {
    if (!shareText) return;
    ReactGA.event({
      category: "Social",
      action: "Share Definition",
      label: "Definition Share",
    });
    if (isMobile && navigator.share) {
      navigator
        .share({
          title: `Check out this rare word from CraftWord!`,
          text: shareText,
          url: `https://www.craftword.game`,
        })
        .then(() => {
          toast({
            title: "Shared successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error("Failed to share:", error);
          toast({
            title: "Failed to share.",
            description: "An unexpected error occurred.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      copyToClipboard(shareText).catch((err) => {
        console.error("Failed to copy:", err);
        toast({
          title: "Failed to copy",
          description: "Could not copy text: " + String(err),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    }
  };

  return (
    <Flex w="100%" alignItems="flex-start" color="gray.500" fontSize="sm">
      {isLoading ? (
        <Spinner size="lg" thickness="4px" speed="0.65s" color="blue.500" />
      ) : (
        <>
          {errorMessage ? (
            <Text>{errorMessage}</Text>
          ) : (
            <Box w="100%">
              <Flex justifyContent="space-between" alignItems="center" mb="2">
                <Box>
                  <Text as="span" fontWeight="medium">
                    {word}
                  </Text>
                  {phoneticData && (
                    <Text as="span" ml={2} fontWeight="medium">
                      /{phoneticData}/
                    </Text>
                  )}
                  <IconButton
                    aria-label="Share"
                    icon={<LuShare fontSize="18px" />}
                    style={{
                      height: "24px",
                      width: "24px",
                      padding: 0,
                      minWidth: "initial",
                    }}
                    onClick={handleShare}
                    borderRadius="0%"
                    variant="ghost"
                    ml={2}
                  />
                </Box>
                {isRare && (
                  <Flex
                    minW="8vh"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Badge variant="solid" bg="grayBrand.500" mr="2">
                      Rare
                    </Badge>
                  </Flex>
                )}
              </Flex>
              <Box ml={4}>
                {definitionData.length > 0 &&
                  definitionData.map((definition, index) => {
                    if (definition.text) {
                      const definitionText = removeHtmlTags(definition.text);
                      return (
                        <Text key={index} fontStyle="italic" mb="1">
                          {definition.partOfSpeech}: {definitionText}
                        </Text>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Box>
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

export default Definition;
