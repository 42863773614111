import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { registerUser, loginUser } from "../api/api";
import { v4 as uuid } from "uuid";
import ForgotPasswordModal from "./ForgotPasswordModal";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  setUserId: React.Dispatch<React.SetStateAction<string | null>>;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  emailLogin: string;
  isLogoutMode: boolean;
  setIsLogoutMode: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string | null;
}

const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onClose,
  setUserId,
  setLoggedIn,
  emailLogin,
  isLogoutMode,
  setIsLogoutMode,
  userId,
}) => {
  const [isRegisterMode, setIsRegisterMode] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleRegister = async () => {
    try {
      await registerUser(email, password, userId);
      setMessage("Registration successful! Please log in.");
      setIsRegisterMode(false);
    } catch (error) {
      setMessage("Registration failed. Please try again.");
    }
  };

  const handleLogin = async () => {
    try {
      const response = await loginUser(email, password);
      localStorage.setItem("userId", response.userId);
      setUserId(response.userId);
      setMessage("Login successful!");
      setLoggedIn(true);
      setEmail("");
      setPassword("");
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      setMessage("Login failed. Please try again.");
    }
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter") {
      if (isRegisterMode) {
        handleRegister();
      } else {
        handleLogin();
      }
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
    setIsFormValid(isEmailValid && password.length > 3);
  }, [email, password]);

  useEffect(() => {
    if (isLogoutMode) {
      setEmail("");
      setPassword("");
      setMessage(" ");
    }
  }, [isLogoutMode]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isLogoutMode
              ? "Logged Out"
              : isRegisterMode
              ? "Register"
              : "Login"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody minH="10vh">
            {isLogoutMode ? (
              <Text textAlign="center">You are now logged out.</Text>
            ) : (
              <VStack spacing={4}>
                {isRegisterMode && (
                  <Text textAlign="center">
                    Register to track your play data across devices and easily
                    restore your progress.
                  </Text>
                )}
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                {message && <Text>{message}</Text>}
                {isRegisterMode && (
                  <Text fontSize="sm">
                    Your email will not be shared. You may occasionally receive
                    CraftWord updates.
                  </Text>
                )}
                {!isRegisterMode && (
                  <Button
                    variant="link"
                    onClick={() => setIsForgotPasswordModalOpen(true)}
                  >
                    Forgot Password?
                  </Button>
                )}
              </VStack>
            )}
          </ModalBody>
          {!isLogoutMode && (
            <ModalFooter>
              <Button
                colorScheme="orangeBrand"
                mr={3}
                onClick={isRegisterMode ? handleRegister : handleLogin}
                isDisabled={!isFormValid}
              >
                {isRegisterMode ? "Register" : "Login"}
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          )}
          {!isLogoutMode && (
            <Text align="center" mb={6}>
              {isRegisterMode ? "Already registered? " : "Need to register? "}
              <Button
                variant="link"
                colorScheme="orangeBrand"
                onClick={() => setIsRegisterMode(!isRegisterMode)}
              >
                {isRegisterMode
                  ? "Click here to login."
                  : "Click here to register."}
              </Button>
            </Text>
          )}
        </ModalContent>
      </Modal>

      <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
      />
    </>
  );
};

export default AuthModal;
