import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

interface InvitationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGoToSite: () => void;
}

const InvitationModal: React.FC<InvitationModalProps> = ({
  isOpen,
  onClose,
  onGoToSite,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Play on craftword.game</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            For the best experience, you are invited to play CraftWord on the
            official website.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="orangeBrand" mr={3} onClick={onGoToSite}>
            Go to Site
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Stay Here
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InvitationModal;
