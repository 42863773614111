// ArchiveDateContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

interface ArchiveDateContextType {
  archiveDate: Date | undefined;
  setArchiveDate: (date: Date | undefined) => void;
}

const defaultState: ArchiveDateContextType = {
  archiveDate: undefined,
  setArchiveDate: () => {},
};

const ArchiveDateContext = createContext<ArchiveDateContextType>(defaultState);

export const useArchiveDate = () => useContext(ArchiveDateContext);

interface ArchiveDateProviderProps {
  children: ReactNode;
}

export const ArchiveDateProvider: React.FC<ArchiveDateProviderProps> = ({
  children,
}) => {
  const [archiveDate, setArchiveDate] = useState<Date | undefined>(undefined);

  return (
    <ArchiveDateContext.Provider value={{ archiveDate, setArchiveDate }}>
      {children}
    </ArchiveDateContext.Provider>
  );
};
