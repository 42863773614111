import React, { useEffect, useState } from "react";
import { Flex, Input } from "@chakra-ui/react";
import "./movetag.css";
import MoveTag, { StaticWordBlock } from "./MoveTag";
import { Move } from "../types";

type RoundMovesProps = {
  moves: Move[];
  start: string;
  goal: string;
  rareWordsUsed: string[];
  userInput: string;
  onSubmitWord: (userInput: string, clearInput: () => void) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isRoundOver: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  isMobile: boolean;
  useVirtualKeyBoard: boolean;
  currentPosition: number | undefined;
};

const RoundMoves: React.FC<RoundMovesProps> = ({
  moves,
  start,
  goal,
  rareWordsUsed,
  userInput,
  handleInputChange,
  handleKeyDown,
  inputRef,
  isMobile,
  useVirtualKeyBoard,
  currentPosition,
}) => {
  const remainingList = moves.length > 1 ? moves.slice(0, -1) : [];
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const itemsInRow = 4;
  const isCenteredLayout = remainingList.length < itemsInRow;
  const hasWon = moves.length > 0 && moves[moves.length - 1].word === goal;
  const movesBeforeInput =
    currentPosition !== undefined ? moves.slice(0, currentPosition) : moves;
  const movesAfterInput =
    currentPosition !== undefined ? moves.slice(currentPosition) : [];

  const renderMoveTag = (move: Move, index: number, isBeforeInput: boolean) => {
    let direction: "forward" | "backward" | "both" = "forward";
    let comparisonWord: string;

    if (isBeforeInput) {
      direction = "forward";
      comparisonWord = index === 0 ? start : moves[index - 1].word;
    } else {
      direction = "backward";
      if (index === movesAfterInput.length - 1) {
        // If it's the last move after input, compare with the goal
        comparisonWord = goal;
      } else {
        // For all other backward moves, compare with the next word
        comparisonWord = movesAfterInput[index + 1].word;
      }
    }

    return (
      <MoveTag
        key={move.id}
        word={move.word}
        comparisonWord={comparisonWord}
        direction={direction}
        isRare={rareWordsUsed.includes(move.word)}
        isWinningMove={move.word === goal}
        shouldAnimate={hasMounted}
      />
    );
  };

  return (
    <Flex
      flexWrap="wrap"
      w="100%"
      justifyContent={isCenteredLayout ? "center" : "flex-start"}
      alignContent="center"
      direction="row"
      gap={3.5}
    >
      <StaticWordBlock word={start} />
      {movesBeforeInput.map((move, index) => renderMoveTag(move, index, true))}
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <Input
          variant="flushed"
          placeholder="Enter word"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          borderColor="grayBrand.500"
          focusBorderColor="grayBrand.500"
          autoFocus
          isDisabled={hasWon}
          readOnly={isMobile && useVirtualKeyBoard}
          ref={inputRef}
          h="32px"
          w="72px"
          _placeholder={{
            fontSize: "14px",
            textAlign: "center",
          }}
          autoComplete="new-password"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          data-lpignore="true"
          data-form-type="other"
          name={Math.random().toString(36).substr(2, 8)}
        />
      </form>
      {movesAfterInput.map((move, index) => renderMoveTag(move, index, false))}
      <StaticWordBlock word={goal} />
    </Flex>
  );
};

export default RoundMoves;
