import { formatDateToLocal } from "../utils/utils";
import { Move, RoundType } from "../components/types";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const fetchDefinition = async (word: string): Promise<any> => {
  try {
    const response = await fetch(`${BASE_URL}/definition/${word}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching definition:", error);
    return null;
  }
};

export const fetchPronunciation = async (word: string): Promise<any> => {
  return await fetch(`${BASE_URL}/pronunciation/${word}`).then(
    async (data) => await data.json(),
  );
};

export const fetchGameRounds = async (selectedDate?: Date): Promise<any> => {
  try {
    const date = selectedDate ?? new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    let CHALLENGE_VERSION;
    const inputDate = new Date(year, month - 1, day);
    const startVersion9 = new Date(2024, 2, 7); // March 7, 2024
    const endVersion9 = new Date(2024, 2, 14); // March 14, 2024
    const startVersion10 = new Date(2024, 2, 15); // March 15, 2024
    const endVersion10 = new Date(2024, 3, 19); // April 19, 2024
    const startVersion11 = new Date(2024, 3, 20); // April 20, 2024
    const endVersion11 = new Date(2024, 3, 24); // April 24, 2024
    const startVersion12 = new Date(2024, 3, 25); // April 25, 2024
    const endVersion12 = new Date(2025, 1, 26); // February 26, 2025

    if (inputDate >= startVersion9 && inputDate <= endVersion9) {
      CHALLENGE_VERSION = 9;
    } else if (inputDate >= startVersion10 && inputDate <= endVersion10) {
      CHALLENGE_VERSION = 10;
    } else if (inputDate >= startVersion11 && inputDate <= endVersion11) {
      CHALLENGE_VERSION = 11;
    } else if (inputDate >= startVersion12 && inputDate <= endVersion12) {
      CHALLENGE_VERSION = 12;
    } else {
      CHALLENGE_VERSION = 8;
    }

    const response = await fetch(
      `${BASE_URL}/wordpairs?date=${formattedDate}&version=${CHALLENGE_VERSION}`,
    );

    if (!response.ok) {
      console.error("Failed to fetch new word pair");
      return {};
    }

    const data = await response.json();

    const gameData = data.rounds.map(
      (round: {
        start_word: string;
        goal_word: string;
        path_length: string;
        best_possible_length: string;
        best_possible_words: string;
      }) => ({
        startWord: round.start_word,
        goalWord: round.goal_word,
        pathLength: round.path_length,
        bestPossibleLength: round.best_possible_length,
        bestPossibleWords: round.best_possible_words,
      }),
    );

    return {
      gameID: data.gameID,
      rounds: gameData,
    };
  } catch (error) {
    console.error("Error fetching new word pair:", error);
    return {};
  }
};

export const fetchWordList = async (): Promise<string[] | null> => {
  try {
    const response = await fetch(`${BASE_URL}/wordlist`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();
    return text.split("\n");
  } catch (error) {
    console.error("Could not load the word list", error);
    return null;
  }
};

export const fetchSCOWL35k = async (): Promise<string[] | null> => {
  try {
    const response = await fetch(`${BASE_URL}/scowl35k`);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();
    return text.split("\n");
  } catch (error) {
    console.error("Error fetching SCOWL35k word list:", error);
    return null;
  }
};

export const storePlayData = async (
  time: number,
  score: number,
  challengeDate: any,
) => {
  const playData = {
    time: time,
    score: score,
    challenge_date: challengeDate,
  };

  try {
    const response = await fetch(`${BASE_URL}/playData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(playData),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error("Failed to store play data");
    }
  } catch (error) {
    console.error("Error storing play data:", error);
  }
};

export const fetchPlayDataByDate = async (date: Date) => {
  const formattedDate = formatDateToLocal(date);
  try {
    const response = await fetch(`/playdata?date=${formattedDate}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const playData = await response.json();
    return playData;
  } catch (error) {
    console.error("Error fetching play data:", error);
    throw error;
  }
};

export const fetchPlayerPercentile = async (
  score: number,
  time: number,
  archiveDate: Date,
) => {
  try {
    const response = await fetch(`/playerPercentile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ score, time, archiveDate }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.percentile;
  } catch (error) {
    throw error;
  }
};

export const fetchAverageScoreByDate = async (date: Date) => {
  const formattedDate = formatDateToLocal(date);

  try {
    const response = await fetch(`${BASE_URL}/averages?date=${formattedDate}`);
    if (!response.ok) {
      throw new Error("Failed to fetch average score by date");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching average score by date:", error);
    return null;
  }
};

export const saveGameState = async (
  userId: string,
  rounds: any[],
  currentRoundIndex: number,
  completed: boolean,
  archiveDate: Date | undefined,
) => {
  const gameState = {
    rounds,
    currentRoundIndex,
    date: archiveDate,
    completed,
  };
  try {
    const response = await fetch(`${BASE_URL}/gameState`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, gameState }),
    });

    if (response.ok) {
    } else {
      console.error(
        "Failed to save game state for user:",
        userId,
        "Server responded with:",
        response.status,
      );
    }
  } catch (error) {
    console.error("Error saving game state for user:", userId, error);
  }
};

export const loadGameState = async (userId: string) => {
  try {
    const response = await fetch(`${BASE_URL}/gameState/${userId}`);
    if (!response.ok) {
      console.error(
        "Failed to load game state for user:",
        userId,
        "Server responded with:",
        response.status,
      );
      throw new Error("Failed to load game state");
    }
    const gameState = await response.json();
    return gameState;
  } catch (error) {
    console.error("Error loading game state for user:", userId, error);
    return null;
  }
};

export const storeSolution = async (
  moves: Move[],
  archiveDate: Date,
  roundIndex: number,
): Promise<any> => {
  const formattedDate = formatDateToLocal(archiveDate);
  const words = moves.map((move) => move.word);
  const solutionData = {
    words,
    date: formattedDate,
    roundIndex,
  };

  try {
    const response = await fetch(`${BASE_URL}/storeSolution`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(solutionData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error storing solution:", error);
    throw error;
  }
};

export const fetchSolutionsData = async (
  archiveDate: string | Date | undefined,
) => {
  if (!archiveDate) return [];

  const formattedDate = formatDateToLocal(archiveDate);
  const response = await fetch(
    `${BASE_URL}/final-game-states/${formattedDate}`,
  );
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

export const saveFinalGameState = async (
  userId: string | null,
  rounds: RoundType[],
  archiveDate: Date | undefined,
  finalActiveTime: number | null,
) => {
  const formattedArchiveDate = archiveDate
    ? formatDateToLocal(archiveDate)
    : undefined;

  // Calculate hintCount and gaveUp
  const hintCount = rounds.reduce(
    (sum, round) => sum + (round.hintCount || 0),
    0,
  );
  const gaveUp = rounds.some((round) => round.gaveUp);

  const dataToSend = {
    userId,
    rounds,
    archiveDate: formattedArchiveDate,
    finalActiveTime,
    hintCount,
    gaveUp,
  };

  try {
    const response = await fetch(`${BASE_URL}/finalGameState`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    if (response.ok) {
      const data = await response.json();
    } else {
      console.error(
        "Failed to save final game state for user:",
        userId,
        "Server responded with:",
        response.status,
      );
    }
  } catch (error) {
    console.error("Error saving final game state for user:", userId, error);
  }
};

export const fetchFinalGameState = async (
  userId: string | undefined,
  archiveDate: Date,
) => {
  const formattedDate = formatDateToLocal(archiveDate);
  const url = new URL(`${BASE_URL}/finalGameState/${userId}`);
  url.searchParams.append("archiveDate", formattedDate);

  try {
    const response = await fetch(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Failed to fetch final game state:", error);
    return null;
  }
};

export const fetchAllFinalGameStates = async (userId: string) => {
  try {
    const response = await fetch(`${BASE_URL}/allFinalGameStates/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      "Failed to fetch all final game states for user:",
      userId,
      error,
    );
    return null;
  }
};

export const registerUser = async (
  email: string,
  password: string,
  userId: string | null,
) => {
  try {
    const response = await fetch("/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, userId }), // Include userId in the body
    });

    if (!response.ok) {
      const errorData = await response.text(); // Use text instead of json
      console.error("Registration failed:", errorData);
      throw new Error("Registration failed");
    }

    return await response.text(); // Use text instead of json
  } catch (error) {
    console.error("Error in registerUser:", error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string) => {
  try {
    const response = await fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      credentials: "include", // Include cookies in requests
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Login failed:", errorData);
      throw new Error("Login failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in loginUser:", error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const response = await fetch("/api/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include cookies in requests
    });

    if (!response.ok) {
      const errorData = await response.text();
      console.error("Logout failed:", errorData);
      throw new Error("Logout failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in logoutUser:", error);
    throw error;
  }
};

export const checkIfLoggedIn = async (): Promise<{
  loggedIn: boolean;
  userId?: string;
  email?: string;
}> => {
  try {
    const response = await fetch("/api/auth/isLoggedIn", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include cookies in requests
    });

    if (!response.ok) {
      throw new Error("Failed to check login status");
    }

    const data = await response.json();
    return { loggedIn: data.loggedIn, userId: data.userId, email: data.email };
  } catch (error) {
    console.error("Error checking login status:", error);
    return { loggedIn: false };
  }
};

export const requestPasswordReset = async (email: string) => {
  try {
    const response = await fetch("/api/auth/request-reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error("Failed to send reset email");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
};

export const resetPassword = async (token: any, newPassword: any) => {
  try {
    const response = await fetch("/api/auth/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, newPassword }),
    });

    if (!response.ok) {
      throw new Error("Failed to reset password");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};
