import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { VStack, Input, Button, Text, Box } from "@chakra-ui/react";
import { resetPassword } from "../api/api";

interface ResetPasswordParams {
  token: string;
}

const ResetPasswordPage: React.FC = () => {
  const { token } = useParams<keyof ResetPasswordParams>() as { token: string };
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async () => {
    if (!token) {
      setMessage("Invalid or expired token");
      return;
    }

    try {
      const data = await resetPassword(token, newPassword);
      setMessage(data.message);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setMessage("Error resetting password");
    }
  };

  return (
    <Box
      width="100%"
      maxWidth="400px"
      mx="auto"
      mt={8}
      p={4}
      boxShadow="md"
      borderRadius="md"
      bg="white"
    >
      <VStack spacing={4}>
        <Text fontSize="lg" fontWeight="bold">
          Enter your new password:
        </Text>
        <Input
          type="password"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {message && <Text>{message}</Text>}
        <Button colorScheme="orangeBrand" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </VStack>
    </Box>
  );
};

export default ResetPasswordPage;
