import { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

function GameCountdown() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  function calculateTimeLeft() {
    const now = new Date();
    const midnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
    );
    const difference = midnight.getTime() - now.getTime();

    const hoursLeft = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutesLeft = Math.floor((difference / 1000 / 60) % 60);
    const secondsLeft = Math.floor((difference / 1000) % 60);

    return {
      hours: String(hoursLeft).padStart(2, "0"),
      minutes: String(minutesLeft).padStart(2, "0"),
      seconds: String(secondsLeft).padStart(2, "0"),
    };
  }

  return (
    <Box
      fontSize="xs"
      fontWeight="semibold"
      textTransform="uppercase"
      color="gray.500"
      width="150px"
    >
      <Text textAlign="center">
        Next game: {timeLeft.hours} : {timeLeft.minutes} : {timeLeft.seconds}
      </Text>
    </Box>
  );
}

export default GameCountdown;
