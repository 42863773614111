import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Box,
  Container,
  Flex,
  Text,
  CircularProgress,
  CircularProgressLabel,
  DrawerFooter,
} from "@chakra-ui/react";
import { RoundType, RoundSolutionData } from "../types";
import { LuTrophy, LuAward } from "react-icons/lu";
import {
  fetchAverageScoreByDate,
  fetchPlayDataByDate,
  fetchPlayerPercentile,
  fetchSolutionsData,
} from "../../api/api";
import { displayDate, isRareWord } from "../../utils/utils";
import Filter from "bad-words";
import DefinitionDrawer from "../Round/DefinitionDrawer";
import { useRareWordList } from "../../RareWordListContext";
import { useArchiveDate } from "../../ArchiveDateContext";

type StatsDrawerProps = {
  rounds: RoundType[];
  isOpen: boolean;
  onClose: () => void;
  totalScore: number;
  sumBestPossibleLength: number;
  totalTime: number | null;
  currentDate: Date;
  onPlayerStatsDrawerOpen: () => void;
};

const StatsDrawer: React.FC<StatsDrawerProps> = ({
  isOpen,
  onClose,
  totalScore,
  sumBestPossibleLength,
  totalTime,
  currentDate,
  rounds,
  onPlayerStatsDrawerOpen,
}) => {
  const { archiveDate } = useArchiveDate();
  const { rareWordList } = useRareWordList();
  const [showSolutions, setShowSolutions] = useState(false);
  const [solutionsData, setSolutionsData] = useState<RoundSolutionData[]>([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");

  const [isRare, setIsRare] = useState(false);

  const handleWordClick = (word: string) => {
    setSelectedWord(word);
    setIsRare(isRareWord(word, rareWordList));
    setDrawerOpen(true);
  };

  const filter = new Filter();

  const [percentile, setPercentile] = useState<number | null>(1);
  const [bestScoreOfDay, setBestScoreOfDay] = useState<number | null>(null);
  const [topScore, setTopScore] = useState<number | null>(null);
  const [totalAverageScore, setTotalAverageScore] = useState<number | null>(
    null,
  );
  const [averageTime, setAverageTime] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen && totalTime !== null && archiveDate) {
      Promise.all([
        fetchAverageScoreByDate(archiveDate),
        fetchPlayDataByDate(archiveDate),
      ])
        .then(([averageScoreResponse, playDataResponse]) => {
          if (averageScoreResponse) {
            setTotalAverageScore(averageScoreResponse.averageScore);
            setAverageTime(averageScoreResponse.averageTime);
          }
          if (playDataResponse) {
            setBestScoreOfDay(playDataResponse.bestScoreOfDay);
          }
        })
        .catch((error) => {
          console.error("Error fetching stats data:", error);
        });

      fetchPlayerPercentile(totalScore, totalTime, archiveDate)
        .then((percentileRank) => {
          setPercentile(percentileRank);
        })

        .catch((error) => {
          console.error("Error fetching player percentile:", error);
          setPercentile(null);
        });
    }
  }, [isOpen, totalScore, totalTime, archiveDate]);

  useEffect(() => {
    if (isOpen && totalTime !== null && archiveDate) {
      fetchPlayerPercentile(totalScore, totalTime, archiveDate)
        .then((percentileRank) => {
          setPercentile(percentileRank);
        })
        .catch((error) => {
          console.error("Error fetching player percentile:", error);
          setPercentile(null);
        });
    }
  }, [isOpen, totalScore, totalTime, archiveDate]);

  useEffect(() => {
    const loadData = async () => {
      if (!archiveDate) {
        setSolutionsData([]);
        console.log(
          "No archive date provided, setting solutions data to empty.",
        );
        return;
      }

      try {
        const data = await fetchSolutionsData(archiveDate);
        const filteredSolutions = data.map((roundData: RoundSolutionData) => {
          const filteredSolutions = roundData.solutions.filter((sol) => {
            const solutionText = Array.isArray(sol.solution)
              ? sol.solution.join(" ")
              : sol.solution;
            return !filter.isProfane(solutionText);
          });
          return {
            ...roundData,
            solutions: filteredSolutions,
          };
        });

        setSolutionsData(filteredSolutions);
        setTopScore(calculateTopScore(filteredSolutions));
      } catch (error) {
        console.error("Error fetching solutions:", error);
        setSolutionsData([]);
        setTopScore(null);
      }
    };

    if (isOpen) {
      loadData();
    }
  }, [archiveDate, isOpen]);

  const toggleView = () => setShowSolutions(!showSolutions);

  const handleAllTimeStatsClick = () => {
    onClose();
    setTimeout(() => {
      onPlayerStatsDrawerOpen();
    }, 200);
  };

  const calculateTopScore = (solutionsData: RoundSolutionData[]) => {
    const shortestSolutionsSum = solutionsData.reduce(
      (sum, roundData, index) => {
        const goalWord = rounds[index]?.goalWord;
        const validSolutions = roundData.solutions.filter((sol) => {
          const solutionArray = Array.isArray(sol.solution)
            ? sol.solution
            : [sol.solution];
          return (
            solutionArray.length > 0 &&
            solutionArray[solutionArray.length - 1] === goalWord
          );
        });
        const shortestSolutionLength = validSolutions.reduce(
          (minLength, sol) => {
            return sol.solution.length < minLength
              ? sol.solution.length
              : minLength;
          },
          Infinity,
        );
        if (shortestSolutionLength !== Infinity) {
        } else {
        }
        return shortestSolutionLength !== Infinity
          ? sum + shortestSolutionLength
          : sum;
      },
      0,
    );
    const finalTopScore = shortestSolutionsSum - sumBestPossibleLength;
    return finalTopScore;
  };

  return (
    <Container>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="auto">
        <DrawerOverlay />
        <DrawerContent
          borderRadius="15px 15px 0px 0px"
          position="fixed"
          width="550px"
          height="60vh"
          mx="auto"
        >
          <DrawerCloseButton onClick={onClose} />
          <DrawerHeader textAlign="center">
            {showSolutions ? "Players' Solutions" : "Statistics"}

            <Text fontSize="sm">{displayDate(archiveDate ?? currentDate)}</Text>
          </DrawerHeader>
          <DrawerBody>
            {showSolutions ? (
              <Flex justifyContent="center">
                <Accordion p={0} width="100%">
                  {solutionsData
                    .slice()
                    .sort((a, b) => a.roundIndex - b.roundIndex)
                    .map((roundData, index) => {
                      const startWord = rounds[roundData.roundIndex]?.startWord;
                      const playerMoves = rounds[
                        roundData.roundIndex
                      ]?.moves.map((move) => move.word);
                      const playerSolutionKey = [
                        startWord,
                        ...playerMoves,
                      ].join(" ");

                      let solutions = roundData.solutions
                        .map((sol) => ({
                          ...sol,
                          length: Array.isArray(sol.solution)
                            ? sol.solution.length
                            : 2,
                          solution: [
                            startWord,
                            ...(Array.isArray(sol.solution)
                              ? sol.solution
                              : [sol.solution]),
                          ],
                        }))
                        .filter((sol) =>
                          sol.solution.includes(
                            rounds[roundData.roundIndex]?.goalWord,
                          ),
                        );

                      solutions.sort((a, b) => {
                        if (a.length !== b.length) return a.length - b.length;
                        return b.proportion - a.proportion;
                      });
                      const topTenSolutions = solutions.slice(0, 10);

                      const playerSolution = solutions.find(
                        (sol) => sol.solution.join(" ") === playerSolutionKey,
                      );

                      if (
                        playerSolution &&
                        !topTenSolutions.includes(playerSolution)
                      ) {
                        topTenSolutions.push(playerSolution);
                      }

                      return (
                        <Accordion
                          key={index}
                          allowMultiple
                          borderColor="grayBrand.100"
                          borderWidth="1px"
                          mb="2"
                        >
                          <AccordionItem>
                            <AccordionButton>
                              <Flex flex="1" justifyContent="space-between">
                                <Text>Round {roundData.roundIndex + 1}</Text>
                                <Text fontSize="sm">(% of players)</Text>
                              </Flex>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel p={1}>
                              {topTenSolutions.map((sol, solIndex, array) => {
                                const isPlayerSolution =
                                  sol.solution.join(" ") === playerSolutionKey;
                                const textColor = isPlayerSolution
                                  ? "orangeBrand.500"
                                  : "black";
                                const nextSol = array[solIndex + 1];
                                const shouldInsertLine =
                                  nextSol && sol.length !== nextSol.length;

                                return (
                                  <React.Fragment key={solIndex}>
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                      mx="2"
                                      whiteSpace="normal"
                                      overflowWrap="break-word"
                                    >
                                      <Flex
                                        justifyContent="space-between"
                                        flexWrap="wrap"
                                      >
                                        {sol.solution.map((word, wordIndex) => (
                                          <Text
                                            textAlign="left"
                                            mx="3px"
                                            key={wordIndex}
                                            color={textColor}
                                            onClick={() =>
                                              handleWordClick(word)
                                            }
                                            sx={{
                                              cursor: "pointer",
                                              whiteSpace: "normal",
                                            }}
                                          >
                                            {word +
                                              (wordIndex <
                                              sol.solution.length - 1
                                                ? " "
                                                : "")}
                                          </Text>
                                        ))}
                                      </Flex>
                                      <Flex direction="row" alignItems="center">
                                        <Flex>
                                          <Text mx="2">
                                            {sol.proportion < 0.01
                                              ? `${(
                                                  sol.proportion * 100
                                                ).toFixed(1)}%`
                                              : `${Math.round(
                                                  sol.proportion * 100,
                                                )}%`}
                                          </Text>
                                        </Flex>
                                        <Flex>
                                          {sol.length ===
                                          rounds[roundData.roundIndex]
                                            ?.bestPossibleLength ? (
                                            <LuAward fontSize="16px" />
                                          ) : sol.length <
                                            (rounds[roundData.roundIndex]
                                              ?.bestPossibleLength ??
                                              Infinity) ? (
                                            <LuTrophy fontSize="14px" />
                                          ) : (
                                            <Box width="16px" height="16px" /> // Placeholder to maintain consistent spacing
                                          )}
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                    {shouldInsertLine && (
                                      <Box
                                        borderTop="1px solid"
                                        borderColor="grayBrand.100"
                                        my="2"
                                      />
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      );
                    })}
                </Accordion>
              </Flex>
            ) : (
              <Flex justifyContent="center" width="100%">
                <Flex direction="column" mb="2" width="100%">
                  <Flex justifyContent="space-between" width="100%" mb="4">
                    <Box flex="1" textAlign="center">
                      <Text fontSize="sm" textColor="grayBrand.500">
                        YOUR SCORE
                      </Text>
                      <Text textColor="orangeBrand.500" fontSize="3xl">
                        {totalScore - sumBestPossibleLength}
                      </Text>
                    </Box>
                    {totalAverageScore !== null && (
                      <Box flex="1" textAlign="center">
                        <Text fontSize="sm" textColor="grayBrand.500">
                          AVERAGE SCORE
                        </Text>
                        <Text fontSize="3xl">
                          {totalAverageScore.toFixed(1)}
                        </Text>
                      </Box>
                    )}
                    {bestScoreOfDay !== null && (
                      <Box flex="1" textAlign="center">
                        <Text fontSize="sm" textColor="grayBrand.500">
                          TOP SCORE
                        </Text>
                        <Text fontSize="3xl">{topScore}</Text>
                      </Box>
                    )}
                  </Flex>
                  <Flex justifyContent="center" width="100%">
                    <Box textAlign="center" flex="1">
                      <Text fontSize="sm" textColor="grayBrand.500">
                        YOUR TIME
                      </Text>
                      <Text fontSize="3xl" textColor="orangeBrand.500">
                        {totalTime !== null
                          ? `${Math.floor(totalTime / 60)}:${
                              totalTime % 60 < 10 ? "0" : ""
                            }${totalTime % 60}`
                          : "N/A"}
                      </Text>
                    </Box>
                    {percentile !== null && (
                      <Box
                        flex="1"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mb="2"
                      >
                        <CircularProgress
                          value={percentile}
                          size="120px"
                          color="orangeBrand.500"
                        >
                          <CircularProgressLabel fontSize="lg">
                            Top {Math.round(100 - percentile)}%
                          </CircularProgressLabel>
                        </CircularProgress>
                      </Box>
                    )}
                    {averageTime !== null && (
                      <Box textAlign="center" flex="1">
                        <Text fontSize="sm" textColor="grayBrand.500">
                          AVERAGE TIME
                        </Text>
                        <Text fontSize="3xl">
                          {`${Math.floor(Math.round(averageTime) / 60)}:${
                            Math.round(averageTime) % 60 < 10 ? "0" : ""
                          }${Math.round(averageTime) % 60}`}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </DrawerBody>
          <DrawerFooter justifyContent="center">
            <Button
              variant="outline"
              colorScheme="orangeBrand"
              onClick={toggleView}
              mr="2"
              width="auto"
              mb="1"
            >
              {showSolutions ? "Statistics" : "Player Solutions"}
            </Button>
            <Button
              variant="outline"
              colorScheme="orangeBrand"
              onClick={handleAllTimeStatsClick}
              ml="2"
              width="auto"
              mb="1"
            >
              All Time Stats
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <DefinitionDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        word={selectedWord}
        isRare={isRare}
      />
    </Container>
  );
};

export default StatsDrawer;
