import React from "react";

const CraftWordLogoLong = ({ width = "281px", height = "65px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 281 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0.5H135.351V64.5H0.5V0.5Z" fill="#EC930D" />
      <path
        d="M35.0606 26.5885C34.7781 24.4936 34.1975 22.8209 33.3188 21.5702C32.4714 20.3195 31.373 19.6941 30.0235 19.6941C28.1091 19.6941 26.587 20.6009 25.4572 22.4144C24.3274 24.1966 23.7625 26.9638 23.7625 30.7158C23.7625 35.2496 24.6412 38.5639 26.3987 40.6588C28.1562 42.7537 30.4315 43.8011 33.2246 43.8011C34.5741 43.8011 35.7667 43.551 36.8024 43.0507C37.838 42.5504 38.5755 41.9564 39.0149 41.2685C39.3915 41.5499 39.7053 41.972 39.9564 42.5348C40.2075 43.0976 40.333 43.6135 40.333 44.0825C40.333 44.8642 39.925 45.6772 39.109 46.5214C38.3245 47.3656 37.179 48.0847 35.6725 48.6788C34.1661 49.2416 32.4244 49.523 30.4472 49.523C27.6854 49.523 25.1591 48.882 22.8681 47.6001C20.6084 46.3181 18.8039 44.3796 17.4544 41.7844C16.1049 39.1892 15.4302 36.0469 15.4302 32.3573C15.4302 28.7616 16.0421 25.7131 17.2661 23.2117C18.4901 20.6791 20.2005 18.7874 22.3973 17.5367C24.6255 16.2547 27.2147 15.6138 30.1647 15.6138C30.8552 15.6138 31.6398 15.7076 32.5185 15.8952C33.3972 16.0828 34.1661 16.3329 34.8252 16.6456C35.2959 16.4267 35.9864 16.2078 36.8965 15.989C37.8066 15.7701 38.5128 15.645 39.0149 15.6138C39.925 17.8337 40.3801 21.492 40.3801 26.5885H35.0606Z"
        fill="white"
      />
      <path
        d="M58.4246 23.3993C59.8369 23.3993 60.8725 23.7589 61.5316 24.478C62.1907 25.1972 62.5202 26.1821 62.5202 27.4328C62.5202 28.7773 62.1593 29.8716 61.4375 30.7158C60.747 31.56 59.8055 31.9821 58.6129 31.9821C57.5773 31.9821 56.7299 31.6851 56.0709 31.091C55.4432 30.4969 55.1294 29.7934 55.1294 28.9805C55.1294 28.4802 55.1764 28.0894 55.2706 27.808C54.1408 28.0894 53.1836 28.574 52.399 29.2619V43.3321C52.399 44.1138 52.5088 44.7079 52.7285 45.1143C52.9482 45.5208 53.3719 45.8335 53.9996 46.0524C54.6586 46.24 55.6315 46.3338 56.9182 46.3338C56.9182 46.8966 56.8555 47.3812 56.7299 47.7877C56.6044 48.1629 56.369 48.5225 56.0238 48.8664H43.4547C42.7329 48.1473 42.372 47.3031 42.372 46.3338C43.2821 46.3338 43.9254 46.1462 44.302 45.771C44.71 45.3645 44.914 44.6922 44.914 43.7542V30.1999C44.914 29.3557 44.6786 28.7616 44.2079 28.4177C43.7685 28.0737 42.9682 27.9018 41.807 27.9018V25.7912C42.8427 25.1972 44.2079 24.6813 45.9026 24.2435C47.6287 23.7745 48.9625 23.54 49.904 23.54C50.6572 23.54 51.1907 23.8058 51.5046 24.3373C51.8184 24.8689 51.9753 25.9319 51.9753 27.5266C52.5402 26.4635 53.4347 25.5098 54.6586 24.6656C55.8826 23.8214 57.1379 23.3993 58.4246 23.3993Z"
        fill="white"
      />
      <path
        d="M63.3297 42.2534C63.3297 40.1585 64.0201 38.5013 65.401 37.2819C66.7818 36.0625 68.8218 35.4528 71.5208 35.4528C72.4623 35.4528 73.4195 35.5466 74.3924 35.7342C75.3966 35.9218 76.2126 36.1719 76.8403 36.4846V30.2468C76.8403 28.9961 76.5264 28.105 75.8988 27.5735C75.2711 27.0107 74.4237 26.7292 73.3567 26.7292C72.321 26.7292 71.4737 26.9794 70.8146 27.4797C71.4109 27.6673 71.8503 27.9956 72.1327 28.4646C72.4466 28.9023 72.6035 29.4026 72.6035 29.9654C72.6035 30.966 72.2426 31.7789 71.5208 32.4042C70.7989 33.0296 69.8888 33.3423 68.7904 33.3423C67.5978 33.3423 66.6406 32.9983 65.9188 32.3104C65.197 31.6226 64.8361 30.7314 64.8361 29.6371C64.8361 28.5427 65.2127 27.5109 65.9659 26.5416C66.7191 25.5724 67.8646 24.7907 69.4024 24.1966C70.9715 23.6025 72.9016 23.3055 75.1926 23.3055C78.2996 23.3055 80.6534 23.9465 82.2539 25.2284C83.8545 26.4791 84.6548 28.3239 84.6548 30.7627V43.1914C84.6548 44.6922 85.3766 45.599 86.8202 45.9117V47.4125C86.4123 47.9753 85.7375 48.413 84.796 48.7257C83.8545 49.0384 82.8973 49.1947 81.9244 49.1947C79.7903 49.1947 78.2839 48.4599 77.4052 46.9904C76.6833 47.6157 75.8046 48.1473 74.769 48.585C73.7333 48.9915 72.5564 49.1947 71.2383 49.1947C68.8845 49.1947 66.9701 48.5537 65.4951 47.2718C64.0515 45.9586 63.3297 44.2858 63.3297 42.2534ZM76.8403 40.096C76.495 39.8771 76.0714 39.6895 75.5692 39.5332C75.0985 39.3768 74.6434 39.2987 74.204 39.2987C73.3253 39.2987 72.6035 39.5801 72.0386 40.1429C71.4737 40.6744 71.1912 41.4561 71.1912 42.4879C71.1912 43.3321 71.4737 44.0356 72.0386 44.5984C72.6035 45.13 73.3567 45.3958 74.2982 45.3958C74.769 45.3958 75.224 45.3332 75.6634 45.2082C76.1341 45.0518 76.5264 44.8642 76.8403 44.6453V40.096Z"
        fill="white"
      />
      <path
        d="M99.8347 10.314C101.247 10.314 102.471 10.611 103.507 11.2051C104.542 11.7992 105.327 12.534 105.86 13.4094C106.425 14.2536 106.708 15.0979 106.708 15.9421C106.708 17.099 106.394 18.0682 105.766 18.8499C105.139 19.6316 104.323 20.0224 103.318 20.0224C102.377 20.0224 101.545 19.6941 100.823 19.0375C100.101 18.3496 99.7406 17.568 99.7406 16.6925C99.7406 15.5669 100.211 14.6601 101.153 13.9722C101.059 13.8159 100.886 13.6908 100.635 13.597C100.415 13.472 100.211 13.4094 100.023 13.4094C99.2698 13.4094 98.5951 13.9253 97.9988 14.9572C97.4339 15.989 97.1514 17.1771 97.1514 18.5216C97.1514 19.4284 97.2299 20.3038 97.3868 21.1481C97.5751 21.9923 97.7791 22.6802 97.9988 23.2117C98.2499 23.7432 98.4852 24.009 98.7049 24.009H103.224C103.224 26.0101 102.957 27.4171 102.424 28.2301H98.4225V43.3321C98.4225 44.0825 98.5323 44.6766 98.752 45.1143C99.0031 45.5208 99.4424 45.8335 100.07 46.0524C100.729 46.24 101.702 46.3338 102.989 46.3338C102.989 47.3031 102.628 48.1473 101.906 48.8664H89.4781C88.7563 48.1473 88.3954 47.3031 88.3954 46.3338C89.3369 46.3338 89.996 46.1305 90.3726 45.7241C90.7806 45.3176 90.9846 44.661 90.9846 43.7542V28.2301H88.113V25.3222C89.1486 25.0721 90.0744 24.65 90.8904 24.0559C90.6393 22.5551 90.5138 21.2106 90.5138 20.0224C90.5138 16.8332 91.3455 14.4256 93.0088 12.7997C94.6721 11.1426 96.9474 10.314 99.8347 10.314Z"
        fill="white"
      />
      <path
        d="M114.727 28.1832V41.4092C114.727 42.3472 114.915 43.0038 115.292 43.379C115.669 43.723 116.202 43.8949 116.893 43.8949C117.489 43.8949 118.116 43.7386 118.776 43.4259C119.435 43.1133 119.952 42.7224 120.329 42.2534C120.643 42.5035 120.863 42.8787 120.988 43.379C121.145 43.8793 121.223 44.3327 121.223 44.7391C121.223 45.4583 120.972 46.1618 120.47 46.8497C119.968 47.5376 119.215 48.1004 118.211 48.5381C117.206 48.9758 115.998 49.1947 114.586 49.1947C112.389 49.1947 110.616 48.5694 109.266 47.3187C107.948 46.068 107.289 43.9887 107.289 41.0809V28.1832H104.323V25.3222C105.139 25.1972 105.924 24.6813 106.677 23.7745C107.462 22.8365 108.121 21.6796 108.654 20.3038C109.219 18.8968 109.58 17.5054 109.737 16.1297C110.302 16.0359 110.82 15.989 111.291 15.989C112.42 15.989 113.252 16.3173 113.786 16.9739C114.35 17.5992 114.633 18.3027 114.633 19.0844V24.009H120.094C120.094 24.947 120.047 25.76 119.952 26.4478C119.858 27.1045 119.654 27.6829 119.34 28.1832H114.727Z"
        fill="white"
      />
      <path d="M135.351 0.5H280.5V64.5H135.351V0.5Z" fill="#D3D3D3" />
      <path
        d="M196.13 16.1297C196.601 16.9426 196.837 17.9275 196.837 19.0844C195.738 19.0844 194.875 19.2564 194.247 19.6003C193.651 19.913 193.243 20.4758 193.023 21.2888L187.092 42.7693C186.464 44.9893 185.664 46.6152 184.691 47.647C183.75 48.6788 182.51 49.1947 180.972 49.1947H178.336L174.005 34.0927L172.169 27.1983L170.663 34.1396L168.262 42.7693C167.634 45.0518 166.881 46.6933 166.002 47.6939C165.124 48.6944 163.915 49.1947 162.377 49.1947H159.835L151.503 21.2888C151.252 20.4445 150.907 19.8661 150.467 19.5534C150.028 19.2408 149.291 19.0844 148.255 19.0844C148.255 17.8963 148.49 16.9114 148.961 16.1297H161.671C162.205 17.1928 162.472 18.1777 162.472 19.0844C161.373 19.0844 160.62 19.272 160.212 19.6472C159.804 20.0224 159.6 20.5227 159.6 21.1481L163.648 36.297L164.402 40.1429L168.874 23.0241C169.125 21.7109 169.533 20.6165 170.098 19.741C170.694 18.8656 171.447 18.4278 172.357 18.4278H177.3L181.961 36.297L182.996 40.3305L184.079 36.2032L187.563 22.6958C187.657 22.3518 187.704 22.0392 187.704 21.7578C187.704 20.9448 187.406 20.3038 186.81 19.8348C186.213 19.3346 185.413 19.0844 184.409 19.0844C184.409 17.8963 184.628 16.9114 185.068 16.1297H196.13Z"
        fill="#1B1B1B"
      />
      <path
        d="M205.716 49.4761C202.264 49.4761 199.502 48.3974 197.431 46.24C195.359 44.0513 194.324 40.9402 194.324 36.9067C194.324 34.0301 194.81 31.5757 195.783 29.5433C196.787 27.5109 198.184 25.9788 199.973 24.947C201.761 23.9152 203.833 23.3993 206.187 23.3993C208.446 23.3993 210.423 23.8996 212.118 24.9001C213.844 25.8694 215.178 27.3077 216.119 29.215C217.092 31.1223 217.579 33.4361 217.579 36.1563C217.579 39.0016 217.077 41.4248 216.072 43.4259C215.099 45.427 213.719 46.9435 211.93 47.9753C210.141 48.9758 208.07 49.4761 205.716 49.4761ZM206.092 46.0993C207.191 46.0993 208.007 45.4739 208.54 44.2232C209.074 42.9725 209.341 40.6588 209.341 37.2819C209.341 34.4366 209.199 32.2479 208.917 30.7158C208.634 29.1837 208.242 28.1363 207.74 27.5735C207.238 27.0107 206.595 26.7292 205.81 26.7292C204.712 26.7292 203.88 27.3546 203.315 28.6053C202.781 29.856 202.515 32.201 202.515 35.6404C202.515 38.4544 202.656 40.6275 202.938 42.1596C203.221 43.6604 203.613 44.6922 204.115 45.2551C204.649 45.8179 205.308 46.0993 206.092 46.0993Z"
        fill="#1B1B1B"
      />
      <path
        d="M236.122 23.3993C237.534 23.3993 238.57 23.7589 239.229 24.478C239.888 25.1972 240.217 26.1821 240.217 27.4328C240.217 28.7773 239.856 29.8716 239.134 30.7158C238.444 31.56 237.503 31.9821 236.31 31.9821C235.274 31.9821 234.427 31.6851 233.768 31.091C233.14 30.4969 232.826 29.7934 232.826 28.9805C232.826 28.4802 232.873 28.0894 232.968 27.808C231.838 28.0894 230.881 28.574 230.096 29.2619V43.3321C230.096 44.1138 230.206 44.7079 230.426 45.1143C230.645 45.5208 231.069 45.8335 231.697 46.0524C232.356 46.24 233.329 46.3338 234.615 46.3338C234.615 46.8966 234.552 47.3812 234.427 47.7877C234.301 48.1629 234.066 48.5225 233.721 48.8664H221.152C220.43 48.1473 220.069 47.3031 220.069 46.3338C220.979 46.3338 221.622 46.1462 221.999 45.771C222.407 45.3645 222.611 44.6922 222.611 43.7542V30.1999C222.611 29.3557 222.376 28.7616 221.905 28.4177C221.466 28.0737 220.665 27.9018 219.504 27.9018V25.7912C220.54 25.1972 221.905 24.6813 223.6 24.2435C225.326 23.7745 226.66 23.54 227.601 23.54C228.354 23.54 228.888 23.8058 229.202 24.3373C229.515 24.8689 229.672 25.9319 229.672 27.5266C230.237 26.4635 231.132 25.5098 232.356 24.6656C233.58 23.8214 234.835 23.3993 236.122 23.3993Z"
        fill="#1B1B1B"
      />
      <path
        d="M266.165 47.6939C265.726 48.2567 265.066 48.6944 264.188 49.0071C263.309 49.3198 262.367 49.4761 261.363 49.4761C260.453 49.4761 259.559 49.2729 258.68 48.8664C257.801 48.4912 257.189 47.9597 256.844 47.2718C256.216 48.0847 255.432 48.6475 254.49 48.9602C253.58 49.3042 252.435 49.4761 251.054 49.4761C249.296 49.4761 247.68 49.0384 246.205 48.1629C244.73 47.2562 243.537 45.9273 242.627 44.1763C241.749 42.3941 241.309 40.221 241.309 37.6571C241.309 34.6242 241.874 32.0447 243.004 29.9185C244.165 27.7923 245.687 26.1977 247.57 25.1346C249.453 24.0715 251.524 23.54 253.784 23.54C254.318 23.54 255.275 23.6025 256.656 23.7276L256.514 20.9136V18.5685C256.514 17.7868 256.295 17.2084 255.855 16.8332C255.416 16.458 254.584 16.2704 253.36 16.2704V14.3474C254.459 13.7534 255.887 13.2218 257.644 12.7528C259.402 12.2838 260.783 12.0493 261.787 12.0493C262.509 12.0493 263.027 12.2213 263.34 12.5652C263.686 12.8779 263.858 13.4251 263.858 14.2067V43.6604C263.858 45.1925 264.611 46.0993 266.118 46.3807L266.165 47.6939ZM254.302 45.4896C255.275 45.4896 256.012 45.2082 256.514 44.6453V28.2301C255.855 27.3233 254.977 26.87 253.878 26.87C252.497 26.87 251.383 27.5891 250.536 29.0274C249.72 30.4657 249.312 32.7951 249.312 36.0156C249.312 39.1111 249.767 41.4717 250.677 43.0976C251.619 44.6922 252.827 45.4896 254.302 45.4896Z"
        fill="#1B1B1B"
      />
    </svg>
  );
};

export default CraftWordLogoLong;
