import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Box,
  Text,
  Heading,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import type { GameDetailType } from "./types";
import DefinitionDrawer from "./Round/DefinitionDrawer";
interface WordsByDate {
  [key: string]: string[];
}
interface WordsByLetter {
  [key: string]: Set<string>;
}

type RareWordsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  gameDetails: GameDetailType[] | undefined;
};

const RareWordsDrawer: React.FC<RareWordsDrawerProps> = ({
  isOpen,
  onClose,
  gameDetails,
}) => {
  const [isDefinitionDrawerOpen, setDefinitionDrawerOpen] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const handleDateClick = (date: string) => {
    setSelectedDate(selectedDate === date ? null : date);
  };

  const handleWordClick = (word: string) => {
    setSelectedWord(word);
    setDefinitionDrawerOpen(true);
  };

  const wordsByDate =
    gameDetails?.reduce<WordsByDate>((acc, detail) => {
      const dateString = new Date(detail.date).toLocaleDateString();
      acc[dateString] = acc[dateString] || [];
      acc[dateString].push(...detail.rareWordsUsed);
      return acc;
    }, {}) || {};

  const totalWordsCount = Object.values(wordsByDate).reduce(
    (total, words) => total + words.length,
    0,
  );

  useEffect(() => {
    // Find the most recent date from the sorted date keys
    const recentDate = Object.keys(wordsByDate).sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    )[0];
    setSelectedDate(recentDate);
  }, [gameDetails]); // Depend on gameDetails to recompute when it changes

  const alphabetizedWords = Object.entries(wordsByDate).reduce<WordsByLetter>(
    (acc, [date, words]) => {
      words.forEach((word) => {
        const letter = word[0].toUpperCase();
        if (!acc[letter]) {
          acc[letter] = new Set<string>(); // Ensure the set is properly initialized
        }
        acc[letter].add(word); // Safely add the word to the set
      });
      return acc;
    },
    {},
  );

  useEffect(() => {
    if (isOpen) {
      const element = document.getElementById("drawer-focusable-element");
      if (element) element.focus();
    }
  }, [isOpen]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent
          p="4"
          borderRadius="15px 15px 0px 0px"
          position="fixed"
          width="550px"
          height="80vh"
          mx="auto"
        >
          <DrawerCloseButton
            position="absolute"
            top="20px"
            right="15px"
            onClick={onClose}
          />
          <DrawerHeader
            textAlign="left"
            borderBottom="1px solid lightgray"
            pb="2"
          >
            Rare Words Crafted: {totalWordsCount} 🩶
          </DrawerHeader>
          <DrawerBody overflowY="auto">
            <Tabs isFitted variant="enclosed">
              <TabList mb="2">
                <Tab>By Date</Tab>
                <Tab>Alphabetized</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SimpleGrid columns={1} spacing="2">
                    {Object.entries(wordsByDate)
                      .sort(
                        (a, b) =>
                          new Date(b[0]).getTime() - new Date(a[0]).getTime(),
                      )
                      .map(([date, words]) => (
                        <Box key={date} mb="4">
                          <Heading
                            size="xs"
                            fontWeight="normal"
                            mb="2"
                            textAlign="center"
                            cursor="pointer"
                            onClick={() => handleDateClick(date)}
                          >
                            {date}
                          </Heading>
                          {selectedDate === date && (
                            <SimpleGrid columns={3} spacing="2">
                              {words.map((word, i) => (
                                <Box
                                  key={i}
                                  p="2"
                                  bg="gray.50"
                                  borderRadius="md"
                                  boxShadow="base"
                                  cursor="pointer"
                                  onClick={() => handleWordClick(word)}
                                  maxW="100px"
                                >
                                  <Text>{word}</Text>
                                </Box>
                              ))}
                            </SimpleGrid>
                          )}
                        </Box>
                      ))}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={3} spacing="2">
                    {Object.entries(alphabetizedWords)
                      .sort((a, b) => a[0].localeCompare(b[0]))
                      .map(([letter, words]) => (
                        <Box key={letter} mb="4">
                          <Heading size="xs" fontWeight="normal" mb="2">
                            {letter}
                          </Heading>
                          {Array.from(words)
                            .sort()
                            .map((word, i) => (
                              <Box
                                key={i}
                                p="2"
                                bg="gray.50"
                                borderRadius="md"
                                boxShadow="base"
                                cursor="pointer"
                                onClick={() => handleWordClick(word)}
                                maxW="100px"
                              >
                                <Text>{word}</Text>
                              </Box>
                            ))}
                        </Box>
                      ))}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {isDefinitionDrawerOpen && (
        <DefinitionDrawer
          isOpen={isDefinitionDrawerOpen}
          onClose={() => setDefinitionDrawerOpen(false)}
          word={selectedWord}
        />
      )}
    </>
  );
};

export default RareWordsDrawer;
