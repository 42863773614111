import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
  HStack,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  LuBarChartHorizontal,
  LuSend,
  LuHelpCircle,
  LuPuzzle,
  LuCalendarDays,
  LuSettings2,
  LuHeart,
  LuGift,
  LuLogIn,
  LuLogOut,
} from "react-icons/lu";
import { handleFeedbackClick } from "../utils/utils";
import { logoutUser } from "../api/api";
import { v4 as uuid } from "uuid";

type GameMenuProps = {
  onAboutModalOpen: () => void;
  onLearnModalOpen: () => void;
  onGameArchiveOpen: () => void;
  onOptionsModalOpen: () => void;
  onRareWordsDrawerOpen: () => void;
  onPlayerStatsDrawerOpen: () => void;
  onAuthModalOpen: () => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  resetGame: () => void;
  userId: string | null;
  setUserId: React.Dispatch<React.SetStateAction<string | null>>;
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  emailLogin: string;
  setIsLogoutMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const GameMenu: React.FC<GameMenuProps> = ({
  onAboutModalOpen,
  onLearnModalOpen,
  onGameArchiveOpen,
  onOptionsModalOpen,
  onRareWordsDrawerOpen,
  onPlayerStatsDrawerOpen,
  isPlaying,
  setIsPlaying,
  resetGame,
  userId,
  setUserId,
  loggedIn,
  setLoggedIn,
  emailLogin,
  onAuthModalOpen,
  setIsLogoutMode,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const paypalDonateLink =
    "https://www.paypal.com/donate/?hosted_button_id=Y3FU5EF7L86T6";

  const confirmGameExit = () => {
    setIsConfirmModalOpen(true);
  };

  const openDonateLink = () => {
    window.open(paypalDonateLink, "_blank");
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem("userId");
      const newUserId = uuid();
      setUserId(newUserId);
      localStorage.setItem("userId", newUserId);
      setIsLogoutMode(true);
      onAuthModalOpen();
      setTimeout(() => {
        onAuthModalOpen();
        setIsLogoutMode(false);
      }, 1500);
    } catch (error) {
      console.error("Logout failed. Please try again.");
    }
  };

  const handleRegisterClick = () => {
    onAuthModalOpen();
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon boxSize={6} />}
          variant="ghost"
        />
        <MenuList borderRadius="0" width="auto">
          <MenuItem onClick={onLearnModalOpen}>
            <HStack>
              <LuHelpCircle />
              <Text>How To Play</Text>
            </HStack>
          </MenuItem>
          {loggedIn ? (
            <MenuItem onClick={handleLogout}>
              <HStack>
                <LuLogOut />
                <Text>Logout {emailLogin}</Text>
              </HStack>
            </MenuItem>
          ) : (
            <MenuItem onClick={handleRegisterClick}>
              <HStack>
                <LuLogIn />
                <Text>Register/Login</Text>
              </HStack>
            </MenuItem>
          )}
          <MenuItem onClick={onPlayerStatsDrawerOpen}>
            <HStack>
              <LuBarChartHorizontal />
              <Text>Player Stats</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={onRareWordsDrawerOpen}>
            <HStack>
              <LuHeart />
              <Text>Rare Words Crafted</Text>
            </HStack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (isPlaying) {
                confirmGameExit();
              } else {
                onGameArchiveOpen();
                resetGame();
                setIsPlaying(false);
              }
            }}
          >
            <HStack>
              <LuCalendarDays />
              <Text>Game Archive</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={onOptionsModalOpen}>
            <HStack>
              <LuSettings2 />
              <Text>Settings</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={handleFeedbackClick}>
            <HStack>
              <LuSend />
              <Text>Feedback</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={onAboutModalOpen}>
            <HStack>
              <LuPuzzle />
              <Text>About CraftWord</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={openDonateLink}>
            <HStack>
              <LuGift />
              <Text>Tip Jar</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Exit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Exit current game?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outline"
              borderColor="grayBrand.700"
              onClick={() => {
                setIsConfirmModalOpen(false);
                onGameArchiveOpen();
                resetGame();
                setIsPlaying(false);
              }}
            >
              Yes, Exit
            </Button>
            <Button
              colorScheme="orangeBrand"
              ml={3}
              onClick={() => setIsConfirmModalOpen(false)}
            >
              No, Stay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GameMenu;
