export const tutorialRounds = [
  {
    startWord: "flood",
    goalWord: "donor",
    moves: [],
    maxMoves: 1,
    startedAt: null,
    completedAt: null,
    pathLength: null,
  },
];
