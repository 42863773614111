import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import CraftWordLogoLong from "../CraftWordLogoLong";
import { displayDate } from "../../utils/utils";
import { LuTrophy, LuAward, LuThumbsUp } from "react-icons/lu";
import { useArchiveDate } from "../../ArchiveDateContext";

type SummaryScoreDisplayProps = {
  totalScore: number;
  sumBestPossibleLength: number;
  fadeIn: string;
  currentDate: Date;
  isTrophyEarned: boolean;
  isGoodScore: boolean;
  isDecentScore: boolean;
  gaveUpRounds: number[]; // Add this prop
};

const SummaryScoreDisplay: React.FC<SummaryScoreDisplayProps> = ({
  totalScore,
  sumBestPossibleLength,
  fadeIn,
  currentDate,
  isTrophyEarned,
  isDecentScore,
  isGoodScore,
  gaveUpRounds, // Add this prop
}) => {
  const { archiveDate } = useArchiveDate();
  const dayScore = totalScore - sumBestPossibleLength;

  let IconComponent = null;
  let kudosText = "";

  if (gaveUpRounds.length > 0) {
    kudosText = "Better Luck Next Time!";
  } else {
    IconComponent = isTrophyEarned
      ? LuTrophy
      : isGoodScore
      ? LuAward
      : isDecentScore
      ? LuThumbsUp
      : null;
    kudosText = isTrophyEarned
      ? "Excellent Score!"
      : isGoodScore
      ? "Great Score!"
      : isDecentScore
      ? "Good Score!"
      : "";
  }

  return (
    <Flex
      alignItems="center"
      color="grayBrand.500"
      fontSize="sm"
      justifyContent="baseline"
      direction="column"
      animation={`${fadeIn} 0.6s`}
      mt="3"
      mx="2"
    >
      <Text textAlign="center" mb="4">
        {displayDate(archiveDate ?? currentDate)}
      </Text>
      <CraftWordLogoLong />
      <Flex alignItems="baseline" mt="2">
        {!gaveUpRounds.length && IconComponent && (
          <>
            <Icon as={IconComponent} color="orangeBrand.500" boxSize={6} />
          </>
        )}
        {gaveUpRounds.length > 0 && <Text fontSize="xx-large">🏳️</Text>}
        <Text
          fontWeight="bold"
          color="orangeBrand.500"
          fontSize="xx-large"
          mx="2"
        >
          {dayScore > 0 ? "+" : dayScore < 0 ? "" : ""}
          {dayScore}
        </Text>
        {gaveUpRounds.length > 0 && <Text fontSize="xx-large">🏳️</Text>}
        {!gaveUpRounds.length && IconComponent && (
          <>
            <Icon as={IconComponent} color="orangeBrand.500" boxSize={6} />
          </>
        )}
      </Flex>
      {kudosText && (
        <Text
          color="orangeBrand.500"
          fontSize="md"
          fontWeight="bold"
          textAlign="center"
          minWidth="200px"
          mt="2"
        >
          {kudosText}
        </Text>
      )}
    </Flex>
  );
};

export default SummaryScoreDisplay;
