import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Flex,
  useToast,
  Button,
  keyframes,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  IconButton,
} from "@chakra-ui/react";
import {
  calculateTotalScore,
  determineTransformation,
  handleTextShare,
  handleTextShareReddit,
} from "../../utils/utils";
import { storePlayData } from "../../api/api";
import { RoundType, GameDetailType } from "../types";
import { IoLogoReddit } from "react-icons/io";
import StatsDrawer from "./StatsDrawer";
import SummaryScoreDisplay from "./SummaryScoreDisplay";
import SummaryWrapMessage from "./SummaryWrapMessage";
import SummaryRoundsDisplay from "./SummaryRoundsDisplay";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useArchiveDate } from "../../ArchiveDateContext";

type GameSummaryProps = {
  rounds: RoundType[];
  activeTime: number;
  gameDetails: GameDetailType[] | undefined;
  finalActiveTime: number | null;
  onPlayerStatsDrawerOpen: () => void;
  onShowAlertMessage: () => void;
};

const GameSummary: React.FC<GameSummaryProps> = ({
  rounds,
  activeTime,
  gameDetails,
  finalActiveTime,
  onPlayerStatsDrawerOpen,
  onShowAlertMessage,
}: GameSummaryProps) => {
  const { archiveDate, setArchiveDate } = useArchiveDate(); // Use the context for archiveDate
  const currentDate = useMemo(() => new Date(), []);
  const toast = useToast();
  const totalScore = calculateTotalScore(rounds);

  const [hideDetails, setHideDetails] = useState(false);

  const toggleAllDisplays = () => {
    setHideDetails(!hideDetails);
  };

  const [currentChallengesPlayed, setCurrentChallengesPlayed] = useState<
    string[]
  >([]);

  useEffect(() => {
    const challengesPlayed = localStorage.getItem("currentChallengesPlayed");
    if (challengesPlayed) {
      setCurrentChallengesPlayed(JSON.parse(challengesPlayed));
    }
  }, []);

  const { currentStreak, longestStreak } = useMemo(() => {
    const gameDetailsDates = gameDetails
      ? gameDetails
          .filter((detail) => detail.completedOnDate)
          .map((detail) => detail.date)
      : [];

    const combinedDates = [...currentChallengesPlayed, ...gameDetailsDates]
      .map((date) => new Date(date + "T00:00:00"))
      .sort((a, b) => a.getTime() - b.getTime());

    let currentStreak = 0,
      longestStreak = 0,
      tempStreak = combinedDates.length > 0 ? 1 : 0;

    for (let i = 1; i < combinedDates.length; i++) {
      if (
        combinedDates[i].getTime() - combinedDates[i - 1].getTime() ===
        86400000
      ) {
        tempStreak++;
      } else {
        longestStreak = Math.max(longestStreak, tempStreak);
        tempStreak = 1;
      }
    }
    longestStreak = Math.max(longestStreak, tempStreak);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const lastGameDate = combinedDates[combinedDates.length - 1];
    if (
      lastGameDate &&
      (lastGameDate.getTime() === today.getTime() ||
        lastGameDate.getTime() === yesterday.getTime())
    ) {
      currentStreak = tempStreak;
    }

    return { currentStreak, longestStreak };
  }, [gameDetails, currentChallengesPlayed]);

  const {
    isOpen: isStatsDrawerOpen,
    onOpen: onStatsDrawerOpen,
    onClose: onStatsDrawerClose,
  } = useDisclosure();

  const [transformationTypes, setTransformationTypes] = useState<string[][]>(
    [],
  );

  useEffect(() => {
    const updatedTransformationTypes: string[][] = rounds.map((round) => {
      const types: string[] = [];
      const allMoves = [
        round.startWord,
        ...round.moves.map(({ word }) => word),
      ];
      for (let i = 0; i < allMoves.length - 1; i++) {
        const type = determineTransformation(allMoves[i], allMoves[i + 1]);
        types.push(type);
      }
      return types;
    });
    setTransformationTypes(updatedTransformationTypes);
  }, [rounds]);

  const sumBestPossibleLength = rounds.reduce(
    (acc, round) => acc + (round.bestPossibleLength ?? 0),
    0,
  );
  const dayScore = totalScore - sumBestPossibleLength;
  const isTrophyEarned = dayScore <= 0;
  const isGoodScore = dayScore === 1 || dayScore === 2;
  const isDecentScore = dayScore === 3 || dayScore === 4 || dayScore === 5;
  const [showWrapMessage, setShowWrapMessage] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
  `;

  useEffect(() => {
    const timer = setTimeout(() => {
      let totalMovesLength = 0;
      rounds.forEach((round) => {
        totalMovesLength += round.moves.length;
      });
      const gametime = Math.round(activeTime / 1000);
      storePlayData(gametime, totalMovesLength, archiveDate);
    }, 4100);

    return () => clearTimeout(timer);
  }, []);

  const openRedditAndShare = async () => {
    await handleTextShareReddit(
      rounds,
      dayScore,
      archiveDate,
      toast,
      openModal,
      setModalContent,
    );

    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await delay(3000); // Delay for 3 seconds
    window.open("https://www.reddit.com/r/CraftWord/", "_blank");
  };

  const totalHints = rounds.reduce(
    (sum, round) => sum + (round.hintCount || 0),
    0,
  );
  const gaveUpRounds = rounds
    .map((round, index) =>
      round.moves.every((move) => move.word === "X") ? index + 1 : null,
    )
    .filter((roundNumber): roundNumber is number => roundNumber !== null);

  const completedRounds = rounds.length - gaveUpRounds.length;

  return (
    <Box w="100%" h="100%" justifyContent="top" flexDirection="column">
      {showWrapMessage ? (
        <Flex
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <SummaryWrapMessage
            currentDate={currentDate}
            totalScore={totalScore}
            sumBestPossibleLength={sumBestPossibleLength}
            dayScore={dayScore}
            setShowWrapMessage={setShowWrapMessage}
            totalHints={totalHints}
            gaveUpRounds={gaveUpRounds}
            completedRounds={completedRounds}
            rounds={rounds}
            onShowAlertMessage={onShowAlertMessage}
          />
        </Flex>
      ) : (
        <>
          <Flex mt="2vh" direction="column">
            <SummaryScoreDisplay
              totalScore={totalScore}
              sumBestPossibleLength={sumBestPossibleLength}
              fadeIn={fadeIn}
              currentDate={currentDate}
              isTrophyEarned={isTrophyEarned}
              isGoodScore={isGoodScore}
              isDecentScore={isDecentScore}
              gaveUpRounds={gaveUpRounds}
            />
            <SummaryRoundsDisplay
              rounds={rounds}
              transformationTypes={transformationTypes}
              hideDetails={hideDetails}
            />
            <Flex
              justifyContent="center"
              alignItems="end"
              direction="row"
              mt="6"
            >
              <Button
                variant="outline"
                borderColor="grayBrand.900"
                onClick={onStatsDrawerOpen}
                mr="4"
              >
                See Stats/Solutions
              </Button>
              <Button
                aria-label="Share game summary"
                variant="orangeBrand"
                onClick={() =>
                  handleTextShare(
                    rounds,
                    dayScore,
                    archiveDate,
                    toast,
                    openModal,
                    setModalContent,
                  )
                }
              >
                Share
              </Button>
            </Flex>
            <Flex justifyContent="center" direction="row" mt="2">
              <IconButton
                aria-label="Toggle All Details"
                icon={
                  hideDetails ? (
                    <LuEyeOff color="gray" />
                  ) : (
                    <LuEye color="gray" />
                  )
                }
                variant="ghost"
                onClick={toggleAllDisplays}
                alignSelf="center"
                mx="2"
              />
              <IconButton
                aria-label="Open Reddit"
                icon={<IoLogoReddit color="gray" fontSize="30px" />}
                variant="ghost"
                onClick={openRedditAndShare}
              />
            </Flex>
          </Flex>
        </>
      )}
      <StatsDrawer
        rounds={rounds}
        isOpen={isStatsDrawerOpen}
        onClose={onStatsDrawerClose}
        totalScore={totalScore}
        sumBestPossibleLength={sumBestPossibleLength}
        totalTime={finalActiveTime}
        currentDate={currentDate}
        onPlayerStatsDrawerOpen={onPlayerStatsDrawerOpen}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="auto"
      >
        <ModalOverlay />
        <ModalContent h="60vh" overflowY="auto">
          <ModalHeader fontSize="md">Copy Text Below To Share</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={modalContent} isReadOnly h="100%" resize="none" />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GameSummary;
