import React, { useState, useEffect, useMemo } from "react";
import {
  subDays,
  format,
  isSameDay,
  startOfDay,
  differenceInCalendarDays,
} from "date-fns";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Button,
  SimpleGrid,
  Stack,
  Text,
  Box,
  Icon,
  Flex,
} from "@chakra-ui/react";
import {
  LuCalendar,
  LuAward,
  LuTrophy,
  LuCalendarCheck,
  LuFlag,
  LuLifeBuoy,
} from "react-icons/lu";
import GameCountdown from "./GameCountdown";
import { fetchAllFinalGameStates } from "../api/api";
import type { GameDetailType } from "./types";

type GameArchiveDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onDateSelect: (date: Date | undefined) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  gameDetails: GameDetailType[] | undefined;
};

const GameArchiveDrawer: React.FC<GameArchiveDrawerProps> = ({
  isOpen,
  onClose,
  onDateSelect,
  setIsPlaying,
  gameDetails, // Add userId prop
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const earliestDate = new Date(2024, 1, 18);
  const [allPlayedDates, setAllPlayedDates] = useState<string[]>([]);

  useEffect(() => {
    const playedDates = localStorage.getItem("allPlayedDates");
    if (playedDates) {
      setAllPlayedDates(JSON.parse(playedDates));
    }
  }, []);

  const playedDatesSet = useMemo(() => {
    const set = new Set(allPlayedDates);
    gameDetails?.forEach((game) => {
      if (game.date) {
        set.add(format(new Date(game.date), "yyyy-MM-dd"));
      }
    });
    return set;
  }, [allPlayedDates, gameDetails]);

  const handleDateButtonClick = (date: Date | undefined) => {
    onDateSelect(date);
    onClose();
  };

  const renderDateButtons = (): React.ReactNode[] => {
    const buttons: React.ReactNode[] = [];
    const today = startOfDay(new Date());
    const totalDays = differenceInCalendarDays(today, earliestDate) + 1;
    const gameDetailsMap = new Map(
      gameDetails?.map((game) => [
        format(new Date(game.date), "yyyy-MM-dd"),
        game,
      ]),
    );

    for (let i = 0; i < totalDays; i++) {
      const date = subDays(today, i);
      const formattedDate = format(date, "yyyy-MM-dd");
      const prettyDate = format(date, "MMM d, yyyy");
      const dayOfWeek = format(date, "EEEE");
      const displayText = isSameDay(date, today) ? "Today" : dayOfWeek;
      const game = gameDetailsMap.get(formattedDate);
      const isPlayed = playedDatesSet.has(formattedDate);

      const gaveUp = game?.gaveUp ?? false;
      const usedHints = (game?.hintCount ?? 0) > 0;

      let mainIcon = LuCalendar;
      let colorScheme = "orangeBrand";

      if (game) {
        if (gaveUp) {
          mainIcon = LuFlag;
        } else {
          mainIcon =
            game.dayScore === 0
              ? LuAward
              : game.dayScore < 0
              ? LuTrophy
              : LuCalendarCheck;
          colorScheme = "gray";
        }
      } else if (isPlayed) {
        mainIcon = LuCalendarCheck;
        colorScheme = "gray";
      }

      buttons.push(
        <Button
          key={formattedDate}
          variant="solid"
          colorScheme={colorScheme}
          onClick={() => handleDateButtonClick(date)}
          size="lg"
          minH="80px"
          borderRadius="0"
          _hover={{
            color: "grayBrand.50",
            bg: isPlayed ? "grayBrand.200" : "orangeBrand.600",
          }}
        >
          <Stack spacing={1} align="center" position="relative">
            <Text fontSize="sm">{displayText}</Text>
            <Text fontSize="xs">{prettyDate}</Text>
            <Box position="relative">
              <Icon as={mainIcon} fontSize="25px" />
              {usedHints && !gaveUp && (
                <Icon
                  as={LuLifeBuoy}
                  fontSize="13px"
                  position="absolute"
                  top="1px"
                  right="-14px"
                />
              )}
            </Box>
          </Stack>
        </Button>,
      );
    }

    return buttons;
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentDate(new Date());
    }
  }, [isOpen]);

  const handleClose = () => {
    onDateSelect(undefined);
    onClose();
    setIsPlaying(false);
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={handleClose}
      size="auto"
    >
      <DrawerOverlay />
      <DrawerContent
        p="4"
        borderRadius="15px 15px 0px 0px"
        position="fixed"
        width="550px"
        height="60vh"
        mx="auto"
      >
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid lightgray"
          pb="2"
        >
          <span>Game Archive</span>
          <Box mr="4">
            <GameCountdown />
          </Box>
        </DrawerHeader>
        <DrawerCloseButton
          position="absolute"
          top="20px"
          right="15px"
          onClick={handleClose}
        />
        <DrawerBody mt="2" mb="10">
          <SimpleGrid columns={3} spacing={3}>
            {renderDateButtons()}
          </SimpleGrid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default GameArchiveDrawer;
