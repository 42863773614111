import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Flex,
  Text,
  Link,
  DrawerFooter,
  HStack,
} from "@chakra-ui/react";
import { handleFeedbackClick } from "../utils/utils";
import { LuGift } from "react-icons/lu";
const paypalDonateLink =
  "https://www.paypal.com/donate/?hosted_button_id=Y3FU5EF7L86T6";
type AboutDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AboutDrawer: React.FC<AboutDrawerProps> = ({
  isOpen,
  onClose,
}: AboutDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent
        p="4"
        borderRadius="15px 15px 0px 0px"
        position="fixed"
        width="550px"
        height="auto"
        mx="auto"
      >
        <DrawerCloseButton
          position="absolute"
          top="20px"
          right="15px"
          onClick={onClose}
        />
        <DrawerHeader
          textAlign="left"
          fontWeight="semibold"
          borderBottom="1px solid lightgray"
          pb="2"
        >
          About CraftWord
        </DrawerHeader>
        <DrawerBody overflowY="auto">
          <Text fontSize="lg" mb={4}>
            CraftWord is an independently made game. I thought of the concept
            while teaching my four-year-old to spell with letter tiles and
            seeing him delight in transforming words. I thought it would make a
            fun game, and thanks to ChatGPT and some help from friends, I taught
            myself to code and made CraftWord! Thanks for playing. 🙏
          </Text>
          <Text fontSize="lg">Luke</Text>
          <Flex
            justifyContent="space-between"
            direction="row"
            as="a"
            href={paypalDonateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text
              fontSize="lg"
              fontWeight="semibold"
              mt="4"
              mb="2"
              color="grayBrand.700"
            >
              Credits
            </Text>
            <HStack>
              <LuGift />
              <Text>Tip Jar</Text>
            </HStack>
          </Flex>
          <Text fontSize="lg">
            <Link
              color="orangeBrand.500"
              onClick={handleFeedbackClick}
              isExternal
            >
              {" "}
              Luke White
            </Link>
            {" - Creator, Developer"}
          </Text>
          <Text fontSize="lg">
            <Link
              href="https://github.com/ACPrice"
              color="orangeBrand.500"
              isExternal
            >
              Andrew Price
            </Link>
            {" - Technical Help, Guru"}
          </Text>
          <Text fontSize="lg">
            <Link
              href="https://kylejwarren.com/"
              color="orangeBrand.500"
              isExternal
            >
              Kyle Warren
            </Link>
            {" - Design Help"}
          </Text>
        </DrawerBody>
        <DrawerFooter
          justifyContent="left"
          fontSize="sm"
          textColor="grayBrand.400"
        >
          Definition data provided by Wordnik
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AboutDrawer;
