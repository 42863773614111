import { useEffect, useState } from "react";
import { type RoundType } from "./types";
import { calculateRoundScore } from "../utils/utils";
import ReactTextTransition, { presets } from "react-text-transition";
import React from "react";

type ScoreAnimationProps = {
  currentRound: RoundType;
  movesLength: number;
};

const ScoreAnimation: React.FC<ScoreAnimationProps> = ({
  currentRound,
  movesLength,
}: ScoreAnimationProps) => {
  const [animatedScore, setAnimatedScore] = useState<number>(
    calculateRoundScore(currentRound),
  );

  useEffect(() => {
    const timerId = setTimeout(() => {
      const newScore = calculateRoundScore(currentRound);
      setAnimatedScore(newScore);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [currentRound, movesLength]);

  return (
    <ReactTextTransition
      springConfig={presets.stiff}
      translateValue="45%"
      inline
    >
      {animatedScore}
    </ReactTextTransition>
  );
};

export default ScoreAnimation;
