import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { StaticWordBlock } from "../Round/MoveTag";
import { getColorFromType, isRareWord } from "../../utils/utils";
import { useRareWordList } from "../../RareWordListContext";
import {
  LuPlus,
  LuMinus,
  LuTriangle,
  LuShuffle,
  LuXCircle,
  LuAward,
  LuTrophy,
  LuFlag,
  LuLifeBuoy,
} from "react-icons/lu";
import { type RoundType } from "../types";

type SummaryRoundsDisplayProps = {
  rounds: RoundType[];
  transformationTypes: string[][];
  hideDetails: boolean;
};

const SummaryRoundsDisplay: React.FC<SummaryRoundsDisplayProps> = ({
  rounds,
  transformationTypes,
  hideDetails,
}) => {
  const { rareWordList } = useRareWordList();

  const getIconFromType = (type: string, hideIcon: boolean) => {
    if (hideIcon) return undefined;
    const color = "grayBrand.50";
    switch (type) {
      case "add":
        return <LuPlus size={20} color={color} />;
      case "remove":
        return <LuMinus size={20} color={color} />;
      case "change":
        return <LuTriangle size={20} color={color} />;
      case "rearrange":
        return <LuShuffle size={20} color={color} />;
      case "incorrect":
        return <LuXCircle size={20} color={color} />;
      default:
        return undefined;
    }
  };

  const isRoundGivenUp = (round: RoundType) => {
    return round.moves.every((move) => move.word === "X");
  };

  return (
    <>
      {rounds.map((round, roundIndex) => {
        const roundTransformations = transformationTypes[roundIndex] || [];
        const bestPathStyle = round.isBestPathAchieved;
        const isShorterThanBestPath =
          round.bestPossibleLength !== null &&
          round.moves.length < round.bestPossibleLength;
        const goldMaxW =
          round.moves.length <= 4 || round.moves.length % 4 === 0
            ? "340px"
            : "308px";

        if (isRoundGivenUp(round)) {
          return (
            <Flex
              key={roundIndex}
              w="auto"
              justifyContent="center"
              alignItems="center"
              mt={4}
              fontSize="2xl"
            >
              🏳️
            </Flex>
          );
        }

        return (
          <Flex
            key={roundIndex}
            w="auto"
            justifyContent="center"
            alignItems="center"
            mt={4}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              gap={1}
              wrap="wrap"
              width="auto"
              maxW={goldMaxW}
              border={bestPathStyle ? "4px solid" : "none"}
              borderRadius="4px"
              borderColor={bestPathStyle ? "orange.200" : "none"}
              bg={bestPathStyle ? "orange.200" : "transparent"}
              position="relative"
            >
              {round.moves.map((move, index) => {
                const transformationType = roundTransformations[index] || "";
                if (transformationType === "") {
                  return null;
                }
                return (
                  <StaticWordBlock
                    key={move.id}
                    word={move.word}
                    icon={getIconFromType(transformationType, hideDetails)}
                    backgroundColor={getColorFromType(transformationType)}
                    textColor="grayBrand.50"
                    hideWord={!hideDetails}
                    isRare={
                      !!move &&
                      isRareWord(move.word, rareWordList) &&
                      hideDetails
                    }
                  />
                );
              })}
              {isShorterThanBestPath ? (
                <Flex justifyContent="right" alignItems="right">
                  <Icon as={LuTrophy} color="orange.500" w={5} h={5} />
                </Flex>
              ) : (
                bestPathStyle && (
                  <Flex justifyContent="right" alignItems="right">
                    <Icon as={LuAward} color="orange.500" w={5} h={5} />
                  </Flex>
                )
              )}
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

export default SummaryRoundsDisplay;
