import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Icon, Text, keyframes } from "@chakra-ui/react";
import Round from "./Round";
import { type RoundType } from "./types";
import {
  LuArrowRight,
  LuArrowLeft,
  LuRotateCcw,
  LuLifeBuoy,
  LuBook,
} from "react-icons/lu";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

type TutorialGameProps = {
  wordList: string[];
  gameLength: number;
  currentRoundIndex: number;
  currentRound: RoundType;
  isRoundOver: boolean;
  addMove: (move: string, direction: "forward" | "backward" | "both") => void;
  advanceRound: () => void;
  onTutorialComplete: () => void;
  winningAnimationOver: boolean;
  undoMove: () => void;
  undoBackwardsMove: () => void;
  clearMoves: () => void;
  firstPlay: boolean;
  setShowCraftwordBestAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showCraftwordBestAlert: boolean;
  isMobile: boolean;
  isSmallScreen: boolean;
  isTutorialModalOpen: boolean;
  useVirtualKeyBoard: boolean;
};

const TutorialGame: React.FC<TutorialGameProps> = ({
  wordList,
  currentRoundIndex,
  currentRound,
  isRoundOver,
  addMove,
  clearMoves,
  advanceRound,
  onTutorialComplete,
  winningAnimationOver,
  undoMove,
  undoBackwardsMove,
  firstPlay,
  setShowCraftwordBestAlert,
  showCraftwordBestAlert,
  isMobile,
  isSmallScreen,
  isTutorialModalOpen,
  useVirtualKeyBoard,
}) => {
  const [tutorialStep, setTutorialStep] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showStartOver, setShowStartOver] = useState(false);
  const [showHelpIcon, setShowHelpIcon] = useState(false);

  const roundTexts = [
    <></>,
    <>
      <Text fontSize="md">Let's do a round together.</Text>
    </>,
    <>
      <Text fontSize="md">
        {"First, craft "}
        <Box as="span" fontSize="md">
          &quot;food&quot;
        </Box>{" "}
        by{" "}
        <Box as="span" color="blueBrand.400" fontWeight="bold" fontSize="md">
          removing
        </Box>
        {" a letter:"}
      </Text>
    </>,
    <>
      <Text fontSize="md">
        {"Now, craft "}
        <Box as="span" fontSize="md">
          &quot;mood&quot;
        </Box>{" "}
        by{" "}
        <Box as="span" color="orangeBrand.500" fontWeight="bold" fontSize="md">
          changing
        </Box>
        {" a letter:"}
      </Text>
    </>,
    <>
      <Text fontSize="md">
        {"Craft "}
        <Box as="span" fontSize="md">
          &quot;doom&quot;
        </Box>{" "}
        by{" "}
        <Box as="span" color="purpleBrand.400" fontWeight="bold" fontSize="md">
          rearranging
        </Box>
        {" letters."}
      </Text>
    </>,
    <>
      <Text fontSize="md">
        {"Craft "}
        <Box as="span" fontSize="md">
          &quot;door&quot;
        </Box>{" "}
        by{" "}
        <Box as="span" color="orangeBrand.500" fontWeight="bold" fontSize="md">
          changing
        </Box>{" "}
        {" a letter."}
      </Text>
    </>,
    <>
      <Text fontSize="md">
        {"Craft the goal word by "}
        <Box as="span" color="greenBrand.500" fontWeight="bold" fontSize="md">
          adding
        </Box>
        {" a letter."}
      </Text>
    </>,
    <>
      <Text fontSize="md">Well done! Click 'Next' to continue.</Text>
    </>,
    <>
      <Flex alignItems="center">
        <Text fontSize="md">
          {isMobile ? "Tap" : "Click"} <Icon as={LuArrowLeft} mx={1} /> to undo
          the last word.
        </Text>
      </Flex>
    </>,
    <>
      <Flex alignItems="center">
        <Text fontSize="md">
          Words can be entered backwards from goal, too.
        </Text>
      </Flex>
    </>,
    <>
      <Flex alignItems="center">
        <Text fontSize="md">
          {isMobile ? "Tap" : "Click"} <Icon as={LuArrowRight} mx={1} /> to undo
          the next word.
        </Text>
      </Flex>
    </>,
    <>
      <Flex alignItems="center">
        <Text fontSize="md">
          {isMobile ? "Tap" : "Click"} <Icon as={LuRotateCcw} mx={1} /> to clear
          all moves and start over.
        </Text>
      </Flex>
    </>,
    <>
      <Flex alignItems="center">
        <Text fontSize="md">
          {isMobile ? "Tap" : "Click"} <Icon as={LuLifeBuoy} mx={1} /> for
          hints, or to give up.
        </Text>
      </Flex>
    </>,
    <>
      <Flex alignItems="center">
        <Icon as={LuBook}></Icon>
        <Text ml="2" fontSize="md">
          {isMobile ? "Tap" : "Click"} any word to see its definition.
        </Text>
      </Flex>
    </>,
    <>
      <Text fontSize="md">
        {isMobile ? "Tap" : "Click"} below to start today's game!
      </Text>
    </>,
  ];

  const [roundText, setRoundText] = useState<React.ReactNode>(roundTexts[0]);
  const [animateText, setAnimateText] = useState(0);

  useEffect(() => {
    if (!isTutorialModalOpen) {
      const initialDelay = 1250;
      const secondDelay = 3000;

      const initialTimeoutId = setTimeout(() => {
        setAnimateText((prevKey) => prevKey + 1);
        setRoundText(roundTexts[1]);

        const secondTimeoutId = setTimeout(() => {
          setAnimateText((prevKey) => prevKey + 1);
          setRoundText(roundTexts[2]);
        }, secondDelay);

        return () => clearTimeout(secondTimeoutId);
      }, initialDelay);

      return () => clearTimeout(initialTimeoutId);
    }
  }, [isTutorialModalOpen]);

  useEffect(() => {
    if (!isTutorialModalOpen) {
      const delay = 1250;

      const timeoutId = setTimeout(() => {
        setAnimateText((prevKey) => prevKey + 1);
        setRoundText(roundTexts[currentRound.moves.length + 2]);
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [currentRound.moves.length]);

  useEffect(() => {
    if (isRoundOver && !winningAnimationOver) {
      setShowNextButton(true);
      setRoundText(roundTexts[7]);
    }
  }, [isRoundOver, winningAnimationOver]);

  const handleNextClick = () => {
    setTutorialStep((prevStep) => {
      const newStep = prevStep + 1;
      setAnimateText((prevKey) => prevKey + 1);
      setRoundText(roundTexts[newStep + 7]);

      switch (newStep) {
        case 1:
          setShowLeftArrow(true);
          break;
        case 3:
          setShowRightArrow(true);
          break;
        case 4:
          setShowStartOver(true);
          break;
        case 5:
          setShowHelpIcon(true);
          break;
        case 7:
          setShowNextButton(false);
          break;
      }

      return newStep;
    });
  };

  return (
    <>
      <Box
        key={animateText}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        minH="35px"
        mt={isMobile ? (isSmallScreen ? "4vh" : "7vh") : "10vh"}
        animation={`${fadeIn} 0.8s`}
      >
        <Text as="div" fontSize="lg" textAlign="center">
          {roundText}
        </Text>
      </Box>
      <Round
        startWord={currentRound.startWord}
        goalWord={currentRound.goalWord}
        moves={currentRound.moves}
        wordList={wordList}
        addMove={(move, direction) => addMove(move, direction)}
        undoMove={undoMove}
        undoBackwardsMove={undoBackwardsMove}
        rareWordsUsed={currentRound.rareWordsUsed}
        isRoundOver={isRoundOver}
        firstPlay={firstPlay}
        craftwordsBestPathLength={10}
        setShowCraftwordBestAlert={setShowCraftwordBestAlert}
        showCraftwordBestAlert={showCraftwordBestAlert}
        clearMoves={clearMoves}
        isMobile={isMobile}
        useVirtualKeyBoard={useVirtualKeyBoard}
        showRightArrow={showRightArrow}
        showLeftArrow={showLeftArrow}
        showStartOver={showStartOver}
        showHelpIcon={showHelpIcon}
        currentPosition={currentRound.currentPosition}
      />
      {showNextButton && tutorialStep < 6 && (
        <Button onClick={handleNextClick} mt={4} variant="ghost">
          Next
        </Button>
      )}
      {tutorialStep === 6 && (
        <Button onClick={onTutorialComplete} mt={4} colorScheme="orangeBrand">
          Start
        </Button>
      )}
    </>
  );
};

export default TutorialGame;
