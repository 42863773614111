import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Box, Button, keyframes, Text } from "@chakra-ui/react";
import { displayDate } from "../../utils/utils";
import { useArchiveDate } from "../../ArchiveDateContext";
import { RoundType } from "../types";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

type SummaryWrapMessageProps = {
  currentDate: Date;
  totalScore: number;
  sumBestPossibleLength: number;
  dayScore: number;
  setShowWrapMessage: Dispatch<SetStateAction<boolean>>;
  totalHints: number;
  gaveUpRounds: number[];
  completedRounds: number;
  rounds: RoundType[];
  onShowAlertMessage: () => void;
};

const SummaryWrapMessage: React.FC<SummaryWrapMessageProps> = ({
  currentDate,
  totalScore,
  sumBestPossibleLength,
  dayScore,
  setShowWrapMessage,
  totalHints,
  gaveUpRounds,
  completedRounds,
  rounds,
  onShowAlertMessage,
}) => {
  const { archiveDate } = useArchiveDate();
  const [buttonOpacity, setButtonOpacity] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonOpacity(1);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  const continueSummary = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setShowWrapMessage(false);
      onShowAlertMessage();
    }, 300);
  };

  const completedRoundNumbers = rounds
    .map((round, index) =>
      round.moves.length > 0 && !gaveUpRounds.includes(index + 1)
        ? index + 1
        : null,
    )
    .filter((roundNumber): roundNumber is number => roundNumber !== null);

  const completedMovesCount = rounds
    .filter((round, index) => !gaveUpRounds.includes(index + 1))
    .reduce((sum, round) => sum + round.moves.length, 0);

  const completedRoundsBestPossibleLength = rounds
    .filter((round, index) => !gaveUpRounds.includes(index + 1))
    .reduce((sum, round) => sum + (round.bestPossibleLength || 0), 0);

  const completedRoundsDayScore =
    completedMovesCount - completedRoundsBestPossibleLength;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      animation={`${fadeIn} 0.6s`}
      sx={{ animationFillMode: "forwards" }}
    >
      <Text textAlign="center" color="grayBrand.500">
        {displayDate(archiveDate ?? currentDate)}
      </Text>
      <Text textAlign="center" fontSize="xx-large" fontWeight="bold" mb="4">
        {gaveUpRounds.length > 0 ? "Game Over" : "Day Complete!"}
      </Text>
      <Text
        fontSize="xl"
        color="grayBrand.500"
        textAlign="center"
        maxWidth="100%"
      >
        {gaveUpRounds.length > 0 && (
          <>
            🏳️ You gave up on round{gaveUpRounds.length > 1 ? "s" : ""}{" "}
            {gaveUpRounds.join(" and ")}.{" "}
          </>
        )}
        {completedRounds > 0 && (
          <>
            {completedRounds < 3 ? (
              <>
                You finished round{completedRoundNumbers.length > 1 ? "s" : ""}{" "}
                {completedRoundNumbers.join(" and ")} in{" "}
              </>
            ) : (
              <>You finished in </>
            )}
            <Text
              as="span"
              fontWeight="bold"
              color="orangeBrand.500"
              fontSize="xx-large"
            >
              {completedMovesCount}
            </Text>{" "}
            moves.{" "}
          </>
        )}
        {completedRounds > 0 && (
          <>
            {completedMovesCount === completedRoundsBestPossibleLength && (
              <>
                You matched CraftWord's solution of{" "}
                <Text
                  as="span"
                  fontWeight="bold"
                  color="greenBrand.500"
                  fontSize="xx-large"
                >
                  {completedRoundsBestPossibleLength}
                </Text>{" "}
                moves!{" "}
              </>
            )}
            {completedMovesCount > completedRoundsBestPossibleLength && (
              <>
                That's{" "}
                <Text
                  as="span"
                  fontWeight="bold"
                  color="blueBrand.400"
                  fontSize="xx-large"
                >
                  {completedRoundsDayScore}
                </Text>{" "}
                {completedRoundsDayScore === 1 ? "move" : "moves"} over
                CraftWord's best of{" "}
                <Text
                  as="span"
                  fontWeight="bold"
                  color="greenBrand.500"
                  fontSize="xx-large"
                >
                  {completedRoundsBestPossibleLength}
                </Text>
                .{" "}
              </>
            )}
            {completedMovesCount < completedRoundsBestPossibleLength && (
              <>
                You beat CraftWord's solution of{" "}
                <Text
                  as="span"
                  fontWeight="bold"
                  color="greenBrand.500"
                  fontSize="xx-large"
                >
                  {completedRoundsBestPossibleLength}
                </Text>{" "}
                moves!{" "}
              </>
            )}
          </>
        )}
        Your score for today is {gaveUpRounds.length > 0 && "🏳️ "}
        <Text
          as="span"
          fontWeight="bold"
          color="orangeBrand.500"
          fontSize="xx-large"
        >
          {completedRoundsDayScore > 0
            ? "+"
            : completedRoundsDayScore < 0
            ? ""
            : ""}
          {completedRoundsDayScore}
        </Text>
        {gaveUpRounds.length > 0 && " 🏳️"}.
      </Text>
      <Button
        mt="8"
        variant="ghost"
        onClick={continueSummary}
        textColor="grayBrand.400"
        style={{
          opacity: buttonOpacity,
          transition: "opacity 0.6s ease-in",
        }}
        isDisabled={isFadingOut}
      >
        Continue
      </Button>
    </Box>
  );
};

export default SummaryWrapMessage;
