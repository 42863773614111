import { extendTheme } from "@chakra-ui/react";

const colors = {
  orangeBrand: {
    50: "#FFF5E5",
    100: "#FFE0B8",
    200: "#FFCB8B",
    300: "#FFB65F",
    400: "#FFA132",
    500: "#EC930D", // 'CraftWord' (aka 'change') orange
    600: "#C7750B",
    700: "#A25808",
    800: "#7D3B06",
    900: "#592003",
  },
  grayBrand: {
    50: "#EDEDED", // bg shade of gray
    100: "#D3D3D3",
    200: "#BABABA",
    300: "#D9D9D9", // move MoveTag gray
    400: "#878787",
    500: "#7A7A7A", // start and goal MoveTag gray
    600: "#6D6D6D",
    700: "#606060",
    800: "#545454",
    900: "#494949",
  },
  greenBrand: {
    50: "#E6F6EC", // lightest shade of green
    100: "#C1E8C8",
    200: "#9BDBA9",
    300: "#76CE89",
    400: "#50C16A",
    500: "#27AE60", // 'add' green
    600: "#1D994C",
    700: "#148536",
    800: "#0C6D28",
    900: "#075820", // darkest shade of green
  },
  blueBrand: {
    50: "#E3F3FF", // lightest shade of blue
    100: "#B6DDFF",
    200: "#89C8FF",
    300: "#5BB3FF",
    400: "#2D9CDB", // 'remove' blue
    500: "#2085B5",
    600: "#176D92",
    700: "#0E55A0",
    800: "#083E4E",
    900: "#03282D", // darkest shade of blue
  },
  purpleBrand: {
    50: "#F3E0FF", // lightest shade of purple
    100: "#DBB7FF",
    200: "#C18FFF",
    300: "#A864FF",
    400: "#BB6BD9", // 'rearrange' red
    500: "#7D29D9",
    600: "#6C1DA6",
    700: "#5A1484",
    800: "#490E61",
    900: "#370A3F", // darkest shade of purple
  },
};

export const theme = extendTheme({
  colors,
  styles: {
    global: {
      body: {
        bg: colors.grayBrand[50],
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "xl",
      },
      variants: {
        outline: {
          borderColor: colors.grayBrand[500],
          color: colors.grayBrand[500],
          _hover: {
            bg: colors.grayBrand[100],
            color: colors.grayBrand[500],
          },
        },
        transparent: {
          bg: "transparent",
          color: "black",
          borderColor: "black",
          _hover: {
            bg: colors.grayBrand[100],
            color: "black",
            borderColor: "black",
          },
        },
        orangeBrand: {
          color: "grayBrand.50",
          bg: colors.orangeBrand[500],
          borderColor: colors.orangeBrand[500],
          _hover: {
            bg: colors.orangeBrand[600],
          },
        },
      },
    },
    Tag: {
      borderRadius: 0,
    },
  },
});

export default theme;
