import React, { useState } from "react";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { displayDate, isRareWord } from "../utils/utils";
import { type RoundType } from "./types";
import { useRareWordList } from "../RareWordListContext";
import { StaticWordBlock } from "./Round/MoveTag";
import RoundResultWrapped from "./RoundResultWrapped";
import { useArchiveDate } from "../ArchiveDateContext";

type GaveUpRoundProps = {
  currentRound: RoundType;
  currentRoundIndex: number;
  advanceRound: () => void;
  isGameOver: boolean;
  setShowGameSummary: (value: boolean) => void;
};

const GaveUpRound: React.FC<GaveUpRoundProps> = ({
  currentRound,
  currentRoundIndex,
  advanceRound,
  isGameOver,
  setShowGameSummary,
}) => {
  const { archiveDate } = useArchiveDate();
  const { rareWordList } = useRareWordList();
  const currentDate = new Date();

  return (
    <Box
      w="100%"
      h="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Text textAlign="center" color="grayBrand.500">
        {displayDate(archiveDate ?? currentDate)}
      </Text>
      <Text textAlign="center" fontSize="xx-large" fontWeight="bold" mb="1">
        Round {currentRoundIndex + 1} Not Completed
      </Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb={4}
        direction="column"
      >
        <Text fontWeight="bold" color="orangeBrand.500" fontSize="xx-large">
          🏳️
        </Text>
      </Flex>
      <Text textAlign="center" fontSize="lg" mb="2">
        CraftWord's Solution:
      </Text>
      <Flex w="100%" justifyContent="center" gap={1} mt={2} mb={4} wrap="wrap">
        <StaticWordBlock
          textColor="grayBrand.50"
          backgroundColor="grayBrand.500"
          word={currentRound.startWord}
          definitions={true}
        />
        {currentRound.bestPossibleWords
          .split(",")
          .slice(1, -1)
          .map((solutionWord, index) => (
            <StaticWordBlock
              key={index}
              word={solutionWord}
              backgroundColor="grayBrand.100"
              textColor="grayBrand.900"
              isRare={isRareWord(solutionWord, rareWordList)}
              definitions={true}
            />
          ))}
        <StaticWordBlock
          textColor="grayBrand.50"
          backgroundColor="grayBrand.500"
          word={currentRound.goalWord}
          definitions={true}
        />
      </Flex>
      <Flex justifyContent="center">
        <Text fontSize="lg" mb="4">
          Better Luck Next Round!
        </Text>
      </Flex>
      <Flex justifyContent="center" mt="1">
        <Button colorScheme="orangeBrand" onClick={advanceRound}>
          Continue
        </Button>
      </Flex>
    </Box>
  );
};

export default GaveUpRound;
