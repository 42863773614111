import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Definition from "./Definition";

type DefinitionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  word?: string;
  isRare?: boolean;
  colorScheme?: any;
};

const DefinitionDrawer: React.FC<DefinitionDrawerProps> = ({
  isOpen,
  onClose,
  word,
  isRare,
  colorScheme,
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
      size="auto"
      colorScheme={colorScheme}
    >
      <DrawerOverlay />
      <DrawerContent
        p="4"
        borderRadius="15px 15px 0px 0px"
        position="fixed"
        width="550px"
        height="60vh"
        mx="auto"
      >
        <DrawerCloseButton
          position="absolute"
          top="20px"
          right="15px"
          onClick={onClose}
        />
        <DrawerHeader
          textAlign="left"
          fontWeight="semibold"
          borderBottom="1px solid lightgray"
          pb="2"
        >
          Definition
        </DrawerHeader>
        <DrawerBody mt="2" mb="10">
          {word && <Definition word={word} isRare={isRare} isOpen={isOpen} />}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DefinitionDrawer;
