import { fetchDefinition } from "../api/api";
import { type RoundType } from "../components/types";
import ReactGA from "react-ga4";

export const isValidTransformation = (word1: string, word2: string) => {
  const lenDiff = word1.length - word2.length;

  if (Math.abs(lenDiff) === 1) {
    if (lenDiff === 1) {
      // Remove a letter
      for (let i = 0; i < word1.length; i++) {
        const modifiedWord = word1.slice(0, i) + word1.slice(i + 1);
        if (modifiedWord.toLowerCase() === word2.toLowerCase()) {
          return true;
        }
      }
    } else {
      // Add a letter
      for (let i = 0; i <= word1.length; i++) {
        for (const letter of "abcdefghijklmnopqrstuvwxyz") {
          const newWord = word1.slice(0, i) + letter + word1.slice(i);
          if (newWord.toLowerCase() === word2.toLowerCase()) {
            return true;
          }
        }
      }
    }
  } else if (lenDiff === 0) {
    // Check for anagrams
    const sortedWord1 = word1.toLowerCase().split("").sort().join("");
    const sortedWord2 = word2.toLowerCase().split("").sort().join("");
    if (sortedWord1 === sortedWord2) {
      return true;
    }

    // Change a single letter
    for (let i = 0; i < word1.length; i++) {
      for (const letter of "abcdefghijklmnopqrstuvwxyz") {
        if (word1[i].toLowerCase() !== letter.toLowerCase()) {
          const newWord = word1.slice(0, i) + letter + word1.slice(i + 1);
          if (newWord.toLowerCase() === word2.toLowerCase()) {
            return true;
          }
        }
      }
    }
  }

  return false;
};

export const isValidWord = (word: string, wordList: string[]) => {
  return wordList.includes(word.toLowerCase());
};

export const isRareWord = (
  word: string,
  rareWordList: Set<string> | null,
): boolean => {
  return rareWordList ? !rareWordList.has(word) : false;
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const formatTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

export const calculateTotalTime = (rounds: RoundType[]): number => {
  return rounds.reduce((totalTime, round) => {
    if (round.completedAt && round.startedAt) {
      return totalTime + (round.completedAt - round.startedAt);
    }
    return totalTime;
  }, 0);
};

export const handleFeedbackClick = () => {
  const emailSubject = encodeURIComponent("Feedback about CraftWord");
  const emailBody = encodeURIComponent(
    "CraftWord is still in development. Please share comments, suggestions, or report bugs below. Thank you for playing!",
  );
  const mailtoLink = `mailto:CraftWordFeedback@gmail.com?subject=${emailSubject}&body=${emailBody}`;
  window.open(mailtoLink, "_blank");
};

export const calculateRoundScore = (round: RoundType): number => {
  let score = round.moves.length;
  return score;
};

export const calculateTotalScore = (
  rounds: RoundType[] | undefined,
): number => {
  if (!rounds) {
    return 0;
  }
  return rounds.reduce((acc, round) => acc + calculateRoundScore(round), 0);
};

export const updatePlayedDates = (date: Date) => {
  const playedDates = JSON.parse(localStorage.getItem("playedDates") ?? "[]");
  const formattedDate = formatDateToLocal(date);
  if (!playedDates.includes(formattedDate)) {
    playedDates.push(formattedDate);
    localStorage.setItem("playedDates", JSON.stringify(playedDates));
  }
};

export const formatDateToLocal = (date: Date | string | undefined) => {
  let dateObj: Date;
  if (typeof date === "string") {
    dateObj = new Date(date);
  } else if (date instanceof Date) {
    dateObj = date;
  } else {
    console.error("formatDateToLocal: Invalid input", date);
    return "";
  }
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const displayDate = (date: Date | string | undefined) => {
  if (!date) {
    return "Date not available";
  }
  const dateObj = typeof date === "string" ? new Date(date) : date;
  const year = dateObj.getFullYear();
  const day = dateObj.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[dateObj.getMonth()];
  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
};

export const determineTransformation = (word1: string, word2: string) => {
  if (word1.toLowerCase() === word2.toLowerCase()) {
    return "incorrect";
  }

  const lenDiff = word1.length - word2.length;
  if (Math.abs(lenDiff) === 1) {
    if (lenDiff === 1) {
      for (let i = 0; i < word1.length; i++) {
        const modifiedWord = word1.slice(0, i) + word1.slice(i + 1);
        if (modifiedWord.toLowerCase() === word2.toLowerCase()) {
          return "remove";
        }
      }
    } else {
      for (let i = 0; i <= word1.length; i++) {
        for (const letter of "abcdefghijklmnopqrstuvwxyz") {
          const newWord = word1.slice(0, i) + letter + word1.slice(i);
          if (newWord.toLowerCase() === word2.toLowerCase()) {
            return "add";
          }
        }
      }
    }
  } else if (lenDiff === 0) {
    const sortedWord1 = word1.toLowerCase().split("").sort().join("");
    const sortedWord2 = word2.toLowerCase().split("").sort().join("");
    if (sortedWord1 === sortedWord2) {
      return "rearrange";
    }
    for (let i = 0; i < word1.length; i++) {
      for (const letter of "abcdefghijklmnopqrstuvwxyz") {
        if (word1[i].toLowerCase() !== letter.toLowerCase()) {
          const newWord = word1.slice(0, i) + letter + word1.slice(i + 1);
          if (newWord.toLowerCase() === word2.toLowerCase()) {
            return "change";
          }
        }
      }
    }
  }
  return "incorrect";
};

export const getColorFromType = (type: string) => {
  switch (type) {
    case "add":
      return "greenBrand.500";
    case "remove":
      return "blueBrand.400";
    case "change":
      return "orangeBrand.500";
    case "rearrange":
      return "purpleBrand.400";
    default:
      return "grayBrand.100";
  }
};

export const getEmojiFromType = (type: string) => {
  switch (type) {
    case "add":
      return "🟩";
    case "remove":
      return "🟦";
    case "change":
      return "🟧";
    case "rearrange":
      return "🟪";
    default:
      return "⬜️";
  }
};

/* export const generateShareText: (
  rounds: RoundType[],
  currentRank: string | null,
) => Promise<string | null> = async (rounds, currentRank) => {
  let highestScore = 0;
  let bestRareWord: string | null = null;
  let bestRound: RoundType | null = null;
  const currentDate = new Date();
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

  let text = `CraftWord ${formattedDate}
  Score: ${calculateTotalScore(rounds)} Rank: ${currentRank}
  Try to beat my score!`;

  for (const round of rounds) {
    const score = calculateRoundScore(round);
    if (round.rareWordsUsed && round.rareWordsUsed.length > 0) {
      if (score > highestScore) {
        highestScore = score;
        bestRound = round;
        bestRareWord = round.rareWordsUsed[0];
      }
    }
  }

  if (bestRound && bestRareWord) {
    try {
      const definitionData = await fetchDefinition(bestRareWord);
      if (
        definitionData &&
        definitionData.statusCode !== 404 &&
        Array.isArray(definitionData) &&
        definitionData.length > 0
      ) {
        const definitionText = definitionData
          .map((def: any) => {
            const cleanText = def.text?.replace(/<[^>]+>/g, "") || "";
            return `${def.partOfSpeech}: ${cleanText}`;
          })
          .join(" ");
        text += `\nHint: I found a shortcut using this rare word:\n"${bestRareWord}"\n${definitionText}`;
      }
    } catch (error) {
      console.error("Failed to fetch definition:", error);
    }
  }

  return text;
}; */

export const readFileToArray = async (filePath: string) => {
  return await fetch(filePath)
    .then(async (response) => await response.text())
    .then((data) => data.split("\n").map((line) => line.replace("\r", "")))
    .catch((error) => {
      console.log("Error:", error);
      return [];
    });
};

export const generateDefinitionShareText: (
  word: string,
) => Promise<string | null> = async (word) => {
  let text = `https://www.craftword.game
Check out this rare word I crafted!\n`;

  try {
    const definitionData = await fetchDefinition(word);
    if (
      definitionData &&
      definitionData.statusCode !== 404 &&
      Array.isArray(definitionData) &&
      definitionData.length > 0
    ) {
      text += `\n'${word}'\n`;
      const definitionText = definitionData
        .map((def: any) => {
          const cleanText = def.text?.replace(/<[^>]+>/g, "") || "";
          return `${
            def.partOfSpeech ? def.partOfSpeech + ": " : ""
          }${cleanText}`;
        })
        .join("\n\n");
      text += `\n${definitionText}`;
    } else {
      text += `\n${word}\n\nUnfortunately, a definition for this word could not be found.`;
    }
  } catch (error) {
    console.error("Failed to fetch definition:", error);
    text += `\n${word}\n\nThere was an error fetching the definition.`;
  }

  return text;
};

/* export const handleShareGraphic = async (
  captureRef: React.RefObject<HTMLElement> | null,
  isMobile: boolean,
  toast: ReturnType<typeof useToast>,
  backgroundColor: string = "#EDEDED",
) => {
  if (!captureRef || !captureRef.current) {
    console.error("No element to capture");
    return;
  }
  const originalBackgroundColor = captureRef.current.style.backgroundColor;
  captureRef.current.style.backgroundColor = backgroundColor;

  try {
    const dataUrl = await toPng(captureRef.current, {
      backgroundColor,
    });
    const blob = await (await fetch(dataUrl)).blob();
    captureRef.current.style.backgroundColor = originalBackgroundColor;

    if (isMobile && navigator.share) {
      const file = new File([blob], "game-summary.png", { type: "image/png" });
      navigator
        .share({
          files: [file],
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      const item = new ClipboardItem({ "image/png": blob });
      await navigator.clipboard.write([item]);
      toast({
        title: "Game Summary Copied",
        description: "Image copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  } catch (error) {
    console.error("Error in handleShare:", error);
    toast({
      title: "Error",
      description: "Could not capture the image",
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  }
}; */

export const handleTextShare = async (
  rounds: RoundType[],
  dayScore: number,
  archiveDate: Date | undefined,
  toast: (options: any) => void,
  openModal: () => void,
  setModalContent: (content: string) => void,
) => {
  // Generating round summaries to build the share message
  const roundsDescriptions = rounds
    .map((round) => {
      if (round.moves.every((move) => move.word === "X")) {
        // Player gave up on this round
        return `⬜️ (🏳️)`;
      }

      const movesDiff =
        round.moves.length - (round.bestPossibleLength ?? round.moves.length);
      const diffText =
        movesDiff > 0 ? `+${movesDiff}` : movesDiff < 0 ? `${movesDiff}` : "0";
      const emojiScore = movesDiff < 0 ? "🏆" : movesDiff === 0 ? "🏅" : "✅";
      const transformationSequence = round.moves
        .map((move, i) => {
          const transformationType = determineTransformation(
            i === 0 ? round.startWord : round.moves[i - 1].word,
            move.word,
          );
          const emoji = getEmojiFromType(transformationType);
          return `${emoji}`;
        })
        .join("");
      return `${transformationSequence} (${diffText}${emojiScore})`;
    })
    .join("\n");

  const dateText = archiveDate
    ? displayDate(archiveDate)
    : displayDate(new Date()); // Use current date if archiveDate is undefined

  // Collecting unique rare words from the final moves of completed rounds
  const rareWordsSet = new Set<string>();
  rounds.forEach((round) => {
    if (!round.moves.every((move) => move.word === "X")) {
      round.rareWordsUsed.forEach((word) => {
        if (round.moves.some((move) => move.word === word)) {
          rareWordsSet.add(word);
        }
      });
    }
  });
  const rareWordsCount = rareWordsSet.size;

  // Calculate score only for completed rounds
  const completedRounds = rounds.filter(
    (round) => !round.moves.every((move) => move.word === "X"),
  );
  const completedDayScore = completedRounds.reduce(
    (score, round) =>
      score +
      (round.moves.length - (round.bestPossibleLength ?? round.moves.length)),
    0,
  );

  const scoreText =
    completedDayScore > 0
      ? `+${completedDayScore} ✅`
      : completedDayScore === 0
      ? "0 🏅"
      : `${completedDayScore} 🏆`;

  const shareMessage = `${dateText}\n\n${roundsDescriptions}\n\nRare words used: ${rareWordsCount} 🩶\n\nScore: ${scoreText}`;
  const clipboardMessage = `https://craftword.game\n${shareMessage}`;

  if (navigator.share) {
    try {
      await navigator.share({
        title: "CraftWord Game Summary",
        text: shareMessage,
        url: "https://craftword.game",
      });
      toast({
        title: "Success",
        description: "Game summary shared successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      ReactGA.event({
        category: "Social",
        action: "Share",
        label: "Game Summary Share Successful",
      });
    } catch (error) {
      console.error("Error sharing:", error);
      fallbackCopyToClipboard(
        clipboardMessage,
        toast,
        openModal,
        setModalContent,
        false,
      );
    }
  } else {
    fallbackCopyToClipboard(
      clipboardMessage,
      toast,
      openModal,
      setModalContent,
      false,
    );
  }
};

function fallbackCopyToClipboard(
  text: string,
  toast: (options: any) => void,
  openModal: () => void,
  setModalContent: (content: string) => void,
  isRedditShare: boolean = false, // New parameter to control toast message
): void {
  if (!navigator.clipboard) {
    toast({
      title: "Copy Failed",
      description:
        "Looks like your browser won't let CraftWord automatically copy text to the clipboard. You can manually copy instead.",
      status: "warning",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
    openModal();
    setModalContent(text);
    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast({
        title: isRedditShare ? "Share your game on r/CraftWord!" : "Copied!",
        description: isRedditShare
          ? "Spoiler-free game summary copied to clipboard."
          : "Game summary copied to clipboard.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      ReactGA.event({
        category: "Social",
        action: "Clipboard",
        label: "Text Copied to Clipboard",
      });
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
      toast({
        title: "Copy Failed",
        description:
          "Failed to copy game summary to clipboard. You can manually copy from below.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      openModal();
      setModalContent(text);
      ReactGA.event({
        category: "Social",
        action: "Clipboard",
        label: "Clipboard Copy Failed",
      });
    });
}

export const defaultGameState = {
  rounds: [],
  currentRoundIndex: 0,
  date: new Date(),
  completed: false,
};

export const handleTextShareReddit = async (
  rounds: RoundType[],
  dayScore: number,
  archiveDate: Date | undefined,
  toast: (options: any) => void,
  openModal: () => void,
  setModalContent: (content: string) => void,
) => {
  // Function to create spoiler text with padding
  const createSpoilerText = (word: string) => {
    const paddedWord = word.padEnd(10, "_");
    return `>! ${paddedWord} !<`;
  };

  // Generating round summaries to build the share message
  const roundsDescriptions = rounds
    .map((round, index) => {
      if (round.moves.every((move) => move.word === "X")) {
        // Player gave up on this round
        return `Round ${index + 1}: Gave Up. (🏳️)`;
      }

      const movesDiff =
        round.moves.length - (round.bestPossibleLength ?? round.moves.length);
      const diffText =
        movesDiff > 0 ? `+${movesDiff}` : movesDiff < 0 ? `${movesDiff}` : "0";
      const emojiScore = movesDiff < 0 ? "🏆" : movesDiff === 0 ? "🏅" : "✅";
      const transformationSequence = round.moves
        .slice(0, -1)
        .map((move) => {
          const word = createSpoilerText(move.word);
          return `${word}`;
        })
        .join(" → ");
      return `Round ${index + 1}: ${
        round.startWord
      } → ${transformationSequence} → ${
        round.goalWord
      } (${diffText}${emojiScore})`;
    })
    .join("\n");

  const dateText = archiveDate
    ? displayDate(archiveDate)
    : displayDate(new Date()); // Use current date if archiveDate is undefined

  // Collecting unique rare words from the final moves of completed rounds
  const rareWordsSet = new Set<string>();
  rounds.forEach((round) => {
    if (!round.moves.every((move) => move.word === "X")) {
      round.rareWordsUsed.forEach((word) => {
        if (round.moves.some((move) => move.word === word)) {
          rareWordsSet.add(word);
        }
      });
    }
  });
  const rareWords = Array.from(rareWordsSet)
    .map((word) => createSpoilerText(word))
    .join(", ");
  const rareWordsCount = rareWordsSet.size;

  // Calculate score only for completed rounds
  const completedRounds = rounds.filter(
    (round) => !round.moves.every((move) => move.word === "X"),
  );
  const completedDayScore = completedRounds.reduce(
    (score, round) =>
      score +
      (round.moves.length - (round.bestPossibleLength ?? round.moves.length)),
    0,
  );

  const scoreText =
    completedDayScore > 0
      ? `+${completedDayScore} ✅`
      : completedDayScore === 0
      ? "0 🏅"
      : `${completedDayScore} 🏆`;

  const shareMessage = `${dateText}\n\n${roundsDescriptions}\n\nRare words used: ${rareWordsCount} 🩶\n${rareWords}\n\nScore: ${scoreText}`;
  const clipboardMessage = `https://craftword.game\n${shareMessage}`;

  // Always copy to clipboard and show the success toast
  fallbackCopyToClipboard(
    clipboardMessage,
    toast,
    openModal,
    setModalContent,
    true,
  );

  ReactGA.event({
    category: "Social",
    action: "Reddit Share",
    label: "Game Summary Share Successful",
  });
};
