import React, { useState, useEffect } from "react";
import { Box, Button, Text, Flex, keyframes } from "@chakra-ui/react";
import { displayDate } from "../utils/utils";
import { useArchiveDate } from "../ArchiveDateContext";

type RoundResultWrappedProps = {
  currentDate: Date;
  archiveDate?: Date;
  bestPossibleScore: number | null;
  roundScore: number;
  isGameOver: boolean;
  advanceRound: () => void;
  setShowGameSummary: (value: boolean) => void;
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const RoundResultWrapped: React.FC<RoundResultWrappedProps> = ({
  currentDate,
  bestPossibleScore,
  roundScore,
  isGameOver,
  advanceRound,
  setShowGameSummary,
}) => {
  const { archiveDate } = useArchiveDate();
  const [buttonOpacity, setButtonOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonOpacity(1);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  const scoreDiff =
    bestPossibleScore !== null ? roundScore - bestPossibleScore : null;

  const moveOrMoves = (number: number) => (number === 1 ? "move" : "moves");

  const continueGame = () => {
    if (isGameOver) {
      setShowGameSummary(true);
    } else {
      advanceRound();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      animation={`${fadeIn} 0.6s, ${fadeOut} 0.3s 4s`}
      sx={{ animationFillMode: "forwards" }}
    >
      <Text textAlign="center" color="grayBrand.500">
        {displayDate(archiveDate ?? currentDate)}
      </Text>
      <Text textAlign="center" fontSize="xx-large" fontWeight="bold" mb="1">
        Round Complete!
      </Text>
      <Flex textAlign="center" direction="row" width="auto">
        <Text fontSize="xl" color="grayBrand.500">
          You finished in{" "}
          <Text
            as="span"
            fontWeight="bold"
            color="orangeBrand.500"
            fontSize="xx-large"
          >
            {roundScore}
          </Text>{" "}
          {moveOrMoves(roundScore)}.{" "}
          {bestPossibleScore !== null && scoreDiff !== null && (
            <>
              {roundScore === bestPossibleScore && (
                <Text as="span">
                  You matched CraftWord's solution of{" "}
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="greenBrand.500"
                    fontSize="xx-large"
                  >
                    {bestPossibleScore}
                  </Text>{" "}
                  {moveOrMoves(bestPossibleScore)}.{" "}
                </Text>
              )}
              {scoreDiff > 0 && (
                <Text as="span">
                  That's{" "}
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="blueBrand.400"
                    fontSize="xx-large"
                  >
                    {scoreDiff}
                  </Text>{" "}
                  {moveOrMoves(scoreDiff)} over CraftWord's best of{" "}
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="greenBrand.500"
                    fontSize="xx-large"
                  >
                    {bestPossibleScore}
                  </Text>
                  .
                </Text>
              )}
              {scoreDiff < 0 && (
                <Text as="span">
                  You beat CraftWord's solution of{" "}
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="greenBrand.500"
                    fontSize="xx-large"
                  >
                    {bestPossibleScore}
                  </Text>{" "}
                  by{" "}
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="orangeBrand.500"
                    fontSize="xx-large"
                  >
                    {-scoreDiff}
                  </Text>{" "}
                  {moveOrMoves(-scoreDiff)}.{" "}
                </Text>
              )}
              <Text as="span">
                {" "}
                Your score for this round is{" "}
                <Text
                  as="span"
                  fontWeight="bold"
                  color="orangeBrand.500"
                  fontSize="xx-large"
                >
                  {scoreDiff}
                </Text>
                .
              </Text>
            </>
          )}
          {bestPossibleScore === null && (
            <Text as="span">Best possible score data is not available.</Text>
          )}
        </Text>
      </Flex>
      <Button
        mt="4"
        variant="ghost"
        onClick={continueGame}
        textColor="grayBrand.400"
        style={{
          opacity: buttonOpacity,
          transition: "opacity 0.6s ease-in", // Smooth transition for opacity change
        }}
      >
        Continue
      </Button>
    </Box>
  );
};

export default RoundResultWrapped;
