import React, { useState, useEffect, useCallback } from "react";
import { nanoid } from "nanoid";
import { Flex } from "@chakra-ui/react";
import {
  fetchGameRounds,
  storeSolution,
  saveGameState,
  saveFinalGameState,
} from "../api/api";
import { isRareWord } from "../utils/utils";
import { useRareWordList } from "../RareWordListContext";
import {
  type RoundType,
  type CustomEventOptions,
  type GameDetailType,
} from "./types";
import Game from "./Game";
import TutorialGame from "./TutorialGame";
import { tutorialRounds } from "./TutorialRounds";
import ReactGA from "react-ga4";
import { useArchiveDate } from "../ArchiveDateContext";

type GameWrapperProps = {
  wordList: string[];
  gameLength: number;
  gameState: any;
  onTutorialComplete: () => void;
  firstPlay: boolean;
  playedTodaysGame: boolean;
  setPlayedTodaysGame: (playedTodaysGame: boolean) => void;
  handleGameArchiveOpen: () => void;
  setTotalScore: (score: number) => void;
  totalScore: number;
  setSumBestPossibleLength: (sumBestPossibleLength: number) => void;
  setTotalTime: (time: number) => void;
  onGameOver: () => void;
  isGameOver: boolean;
  setResetCurrentRoundIndex: (resetFunction: () => void) => void;
  resetRounds: (resetFunction: () => void) => void;
  finalActiveTime: number | null;
  setFinalActiveTime: (finalActiveTime: number | null) => void;
  gameDetails: GameDetailType[] | undefined;
  setShowGameSummary: (showGameSummary: boolean) => void;
  showGameSummary: boolean;
  userId: string | null;
  isMobile: boolean;
  useVirtualKeyBoard: boolean;
  marginTop: string;
  isSmallScreen: boolean;
  isTutorialModalOpen: boolean;
  onPlayerStatsDrawerOpen: () => void;
  onShowAlertMessage: () => void;
};

const GameWrapper: React.FC<GameWrapperProps> = ({
  wordList,
  gameLength,
  gameState,
  onTutorialComplete,
  firstPlay,
  playedTodaysGame,
  setPlayedTodaysGame,
  handleGameArchiveOpen,
  setTotalScore,
  setSumBestPossibleLength,
  setTotalTime,
  onGameOver,
  isGameOver,
  setResetCurrentRoundIndex,
  resetRounds,
  finalActiveTime,
  setFinalActiveTime,
  gameDetails,
  setShowGameSummary,
  showGameSummary,
  userId,
  isMobile,
  useVirtualKeyBoard,
  marginTop,
  isSmallScreen,
  isTutorialModalOpen,
  onPlayerStatsDrawerOpen,
  onShowAlertMessage,
}) => {
  const { archiveDate, setArchiveDate } = useArchiveDate();

  const [rounds, setRounds] = useState<RoundType[]>([]);
  useEffect(() => {
    resetRounds(() => () => {
      setRounds([]);
    });
  }, [resetRounds]);
  const [currentRoundIndex, setCurrentRoundIndex] = useState<number>(0);
  useEffect(() => {
    setResetCurrentRoundIndex(() => () => {
      setCurrentRoundIndex(0);
    });
  }, [setResetCurrentRoundIndex]);
  const [winningAnimationOver, setWinningAnimationOver] = useState(false);
  const [showCraftwordBestAlert, setShowCraftwordBestAlert] = useState(false);
  const { rareWordList } = useRareWordList();
  const trackEvent = (eventOptions: CustomEventOptions) => {
    ReactGA.event(eventOptions);
  };

  const fetchRounds = useCallback(async () => {
    const gameData = await fetchGameRounds(archiveDate);
    const newRounds = gameData.rounds.map((roundData: any) => {
      const isBestPath =
        roundData.bestPossibleLength !== null &&
        roundData.pathLength <= roundData.bestPossibleLength;

      return {
        ...roundData,
        maxMoves: parseInt(roundData.pathLength) + 1,
        moves: [],
        startedAt: Date.now(),
        completedAt: null,
        rareWordsUsed: [],
        usedUndo: false,
        isBestPathAchieved: isBestPath,
        hintCount: 0,
        gaveUp: false,
        currentPosition: 0,
      };
    });

    return newRounds;
  }, [archiveDate]);

  const addAllTutorialRounds = () => {
    const allTutorialRounds = tutorialRounds.map((roundData) => ({
      ...roundData,
      maxMoves: 5,
      moves: [],
      startedAt: Date.now(),
      completedAt: null,
      rareWordsUsed: [],
      usedUndo: false,
      bestPossibleLength: 0,
      bestPossibleWords: "",
      hintCount: 0,
      gaveUp: false,
      currentPosition: 0, // Adding the missing currentPosition
    }));
    setRounds(allTutorialRounds);
  };

  const [isFetchAndSetRoundsCompleted, setIsFetchAndSetRoundsCompleted] =
    useState(false);

  const loadGame = useCallback(() => {
    const fetchAndSetRounds = async () => {
      if (!firstPlay && gameState.rounds.length > 0) {
        setRounds(gameState.rounds);
        setCurrentRoundIndex(gameState.currentRoundIndex);
        setIsFetchAndSetRoundsCompleted(true);
      } else {
        if (firstPlay) {
          setCurrentRoundIndex(0);
          addAllTutorialRounds();
          setIsFetchAndSetRoundsCompleted(false);
        } else {
          const rounds = await fetchRounds();
          setCurrentRoundIndex(0);
          setRounds(rounds);
          trackEvent({
            category: "Game",
            action: "Start",
            label: process.env.NODE_ENV,
            challenge_date: archiveDate,
          });
          setIsFetchAndSetRoundsCompleted(true);
        }
      }
    };
    void fetchAndSetRounds();
  }, [fetchRounds, gameState, firstPlay, archiveDate]);

  useEffect(() => {
    loadGame();
  }, [loadGame, firstPlay]);

  const currentRound = rounds ? rounds[currentRoundIndex] : null;

  const saveGameStateIfNecessary = () => {
    if (userId && !firstPlay && archiveDate !== undefined) {
      saveGameState(
        userId,
        rounds,
        currentRoundIndex,
        isGameOver,
        archiveDate,
      ).catch((error) => {
        console.error("Error saving game state:", error);
      });
    }
  };

  const advanceRound = useCallback(async () => {
    const currentRound = rounds[currentRoundIndex];
    const moves = currentRound.moves;

    if (archiveDate && !currentRound.gaveUp) {
      // Only store if player didn't give up
      await storeSolution(moves, archiveDate, currentRoundIndex).catch(
        (error) => console.error("Error storing solution:", error),
      );
    } else {
      console.log(
        "Skipping solution storage: player gave up or archive date is unavailable.",
      );
    }

    if (currentRoundIndex === gameLength - 1) {
      trackEvent({
        category: "Game",
        action: "Complete Day",
        label: process.env.NODE_ENV,
      });
      onGameOver();
      saveFinalGameState(userId, rounds, archiveDate, finalActiveTime);
      setShowGameSummary(true);
    } else {
      const nextRoundIndex = currentRoundIndex + 1;
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        if (updatedRounds[nextRoundIndex]) {
          updatedRounds[nextRoundIndex].startedAt = Date.now();
        }
        return updatedRounds;
      });
      setCurrentRoundIndex(nextRoundIndex);
    }
    saveGameStateIfNecessary();
  }, [
    currentRoundIndex,
    gameLength,
    onGameOver,
    rounds,
    archiveDate,
    storeSolution,
  ]);

  const checkRareWord = useCallback(
    (word: string) => {
      const rare = isRareWord(word, rareWordList);
      if (rare) {
        if (currentRoundIndex !== null && rounds[currentRoundIndex]) {
          setRounds((prevRounds: RoundType[]) => {
            const updatedRounds = [...prevRounds];
            const newRound: RoundType = {
              ...updatedRounds[currentRoundIndex],
            };
            newRound.rareWordsUsed.push(word);
            updatedRounds[currentRoundIndex] = newRound;
            return updatedRounds;
          });
        }
      }
    },
    [currentRoundIndex, rounds, setRounds, rareWordList],
  );

  const [didGiveUp, setDidGiveUp] = useState(false);

  const addMove = useCallback(
    (move: string, direction: "forward" | "backward" | "both") => {
      setRounds((prevRounds: RoundType[]) => {
        const updatedRounds = [...prevRounds];
        const round = updatedRounds[currentRoundIndex];
        const { moves, currentPosition, goalWord, bestPossibleLength } = round;
        let newMove = { id: nanoid(), word: move };
        let newPosition = currentPosition;

        if (direction === "both") {
          moves.splice(currentPosition, 0, newMove);
          newPosition = moves.length;
        } else if (direction === "forward") {
          moves.splice(currentPosition, 0, newMove);
          newPosition = currentPosition + 1;
        } else if (direction === "backward") {
          moves.splice(currentPosition, 0, newMove);
        }

        round.currentPosition = newPosition;

        // Check if the move completes the round
        if (move === goalWord) {
          round.isBestPathAchieved =
            bestPossibleLength !== null && moves.length <= bestPossibleLength;
          if (currentRound) {
            trackEvent({
              category: "Game",
              action: "Complete Round",
              label: process.env.NODE_ENV,
              round: currentRoundIndex,
              score: moves.length,
              challenge_date: archiveDate,
            });
          } else {
            console.error("currentRound is null");
          }
          round.completedAt = Date.now();
          setWinningAnimationOver(true);
          setTimeout(() => setWinningAnimationOver(false), 2000);
          setDidGiveUp(false); // Reset didGiveUp when the round is completed successfully
        }

        // Check for rare word
        checkRareWord(move);
        return updatedRounds;
      });

      saveGameStateIfNecessary();
    },
    [
      setRounds,
      currentRoundIndex,
      checkRareWord,
      currentRound,
      archiveDate,
      setWinningAnimationOver,
      setDidGiveUp,
      trackEvent,
      saveGameStateIfNecessary,
    ],
  );

  const clearMoves = useCallback(() => {
    setRounds((prevRounds: RoundType[]) => {
      const updatedRounds = [...prevRounds];
      if (updatedRounds[currentRoundIndex]) {
        const newRound: RoundType = { ...updatedRounds[currentRoundIndex] };
        newRound.moves = [];
        newRound.currentPosition = 0; // Reset currentPosition
        updatedRounds[currentRoundIndex] = newRound;
      }
      return updatedRounds;
    });
    saveGameStateIfNecessary();
  }, [setRounds, currentRoundIndex, saveGameStateIfNecessary]);

  const undoMove = useCallback(() => {
    setRounds((prevRounds: RoundType[]) => {
      const updatedRounds = [...prevRounds];
      const currentRound = { ...updatedRounds[currentRoundIndex] };
      if (currentRound.moves.length > 0 && currentRound.currentPosition > 0) {
        const lastMove = currentRound.moves.splice(
          currentRound.currentPosition - 1,
          1,
        )[0];
        currentRound.currentPosition -= 1;
        if (lastMove && currentRound.rareWordsUsed.includes(lastMove.word)) {
          const rareWordIndex = currentRound.rareWordsUsed.indexOf(
            lastMove.word,
          );
          currentRound.rareWordsUsed.splice(rareWordIndex, 1);
        }
      }
      updatedRounds[currentRoundIndex] = currentRound;
      return updatedRounds;
    });
    saveGameStateIfNecessary();
  }, [setRounds, currentRoundIndex, saveGameStateIfNecessary]);

  const undoBackwardsMove = useCallback(() => {
    setRounds((prevRounds: RoundType[]) => {
      const updatedRounds = [...prevRounds];
      const currentRound = { ...updatedRounds[currentRoundIndex] };

      // Check if there is a move immediately after the currentPosition to undo
      if (currentRound.moves.length > currentRound.currentPosition) {
        const nextMoveIndex = currentRound.currentPosition;
        const moveToRemove = currentRound.moves[nextMoveIndex];
        currentRound.moves.splice(nextMoveIndex, 1);

        // Remove the word from rareWordsUsed if it's in there
        if (
          moveToRemove &&
          currentRound.rareWordsUsed.includes(moveToRemove.word)
        ) {
          const rareWordIndex = currentRound.rareWordsUsed.indexOf(
            moveToRemove.word,
          );
          currentRound.rareWordsUsed.splice(rareWordIndex, 1);
        }
      }

      updatedRounds[currentRoundIndex] = currentRound;
      return updatedRounds;
    });
    saveGameStateIfNecessary();
  }, [setRounds, currentRoundIndex, saveGameStateIfNecessary]);

  const incrementHintCount = useCallback(() => {
    setRounds((prevRounds) => {
      const newRounds = [...prevRounds];
      newRounds[currentRoundIndex] = {
        ...newRounds[currentRoundIndex],
        hintCount: (newRounds[currentRoundIndex].hintCount || 0) + 1,
      };
      return newRounds;
    });
  }, [currentRoundIndex]);

  const giveUpRequested = useCallback(() => {
    setRounds((prevRounds) => {
      const newRounds = [...prevRounds];
      const currentRound = newRounds[currentRoundIndex];
      if (currentRound && currentRound.bestPossibleLength) {
        const giveUpMoves = Array(currentRound.bestPossibleLength).fill({
          id: nanoid(),
          word: "X",
        });
        newRounds[currentRoundIndex] = {
          ...currentRound,
          moves: giveUpMoves,
          completedAt: Date.now(),
          gaveUp: true,
        };
      }
      return newRounds;
    });
    setDidGiveUp(true);
    saveGameStateIfNecessary();
  }, [currentRoundIndex, saveGameStateIfNecessary]);

  useEffect(() => {
    const sumBestPossibleLength = rounds.reduce(
      (acc, round) => acc + (round.bestPossibleLength ?? 0),
      0,
    );
    setSumBestPossibleLength(sumBestPossibleLength);
  }, [rounds, setSumBestPossibleLength]);

  if (!currentRound) {
    return null;
  }

  const isRoundOver = !(currentRound.completedAt == null);

  return (
    <Flex
      flex="1"
      w="100%"
      direction="column"
      align="center"
      justify="top"
      mt="4"
    >
      <>
        {firstPlay ? (
          <TutorialGame
            wordList={wordList}
            gameLength={gameLength}
            currentRoundIndex={currentRoundIndex}
            currentRound={currentRound}
            isRoundOver={isRoundOver}
            addMove={(move, direction) => addMove(move, direction)}
            advanceRound={advanceRound}
            onTutorialComplete={onTutorialComplete}
            winningAnimationOver={winningAnimationOver}
            undoMove={undoMove}
            undoBackwardsMove={undoBackwardsMove}
            firstPlay={firstPlay}
            setShowCraftwordBestAlert={setShowCraftwordBestAlert}
            showCraftwordBestAlert={showCraftwordBestAlert}
            clearMoves={clearMoves}
            isMobile={isMobile}
            isSmallScreen={isSmallScreen}
            isTutorialModalOpen={isTutorialModalOpen}
            useVirtualKeyBoard={useVirtualKeyBoard}
          />
        ) : (
          <Game
            wordList={wordList}
            gameLength={gameLength}
            rounds={rounds}
            currentRoundIndex={currentRoundIndex}
            setRounds={setRounds}
            setCurrentRoundIndex={setCurrentRoundIndex}
            currentRound={currentRound}
            isRoundOver={isRoundOver}
            isGameOver={isGameOver}
            addMove={(move, direction) => addMove(move, direction)}
            undoMove={undoMove}
            advanceRound={advanceRound}
            setTotalTime={setTotalTime}
            setTotalScore={setTotalScore}
            winningAnimationOver={winningAnimationOver}
            finalActiveTime={finalActiveTime}
            setFinalActiveTime={setFinalActiveTime}
            firstPlay={firstPlay}
            isFetchAndSetRoundsCompleted={isFetchAndSetRoundsCompleted}
            playedTodaysGame={playedTodaysGame}
            setPlayedTodaysGame={setPlayedTodaysGame}
            gameDetails={gameDetails}
            showGameSummary={showGameSummary}
            setShowGameSummary={setShowGameSummary}
            setShowCraftwordBestAlert={setShowCraftwordBestAlert}
            showCraftwordBestAlert={showCraftwordBestAlert}
            clearMoves={clearMoves}
            isMobile={isMobile}
            marginTop={marginTop}
            isSmallScreen={isSmallScreen}
            useVirtualKeyBoard={useVirtualKeyBoard}
            onPlayerStatsDrawerOpen={onPlayerStatsDrawerOpen}
            onHintRequested={incrementHintCount}
            onGiveUpRequested={giveUpRequested}
            didGiveUp={didGiveUp}
            setDidGiveUp={setDidGiveUp}
            onShowAlertMessage={onShowAlertMessage}
            undoBackwardsMove={undoBackwardsMove}
          />
        )}
      </>
    </Flex>
  );
};

export default GameWrapper;
