import React, { createContext, useContext, useState } from "react";

type DefinitionData = {
  text: string;
  partOfSpeech: string;
};

type DefinitionContextType = {
  definitionMap: Record<
    string,
    {
      definition: DefinitionData[];
      pronunciation: string | null;
    }
  >;
  setDefinitionInMap: (
    word: string,
    data: { definition: DefinitionData[]; pronunciation: string | null },
  ) => void;
};

const DefinitionContext = createContext<DefinitionContextType | undefined>(
  undefined,
);

type DefinitionProviderProps = {
  children: React.ReactNode;
};

export const DefinitionProvider: React.FC<DefinitionProviderProps> = ({
  children,
}) => {
  const [definitionMap, setDefinitionMap] = useState<
    DefinitionContextType["definitionMap"]
  >({});

  const setDefinitionInMap = (
    word: string,
    data: { definition: DefinitionData[]; pronunciation: string | null },
  ) => {
    setDefinitionMap((prevMap) => ({
      ...prevMap,
      [word]: data,
    }));
  };

  return (
    <DefinitionContext.Provider value={{ definitionMap, setDefinitionInMap }}>
      {children}
    </DefinitionContext.Provider>
  );
};

export const useDefinitionContext = () => {
  const context = useContext(DefinitionContext);
  if (!context) {
    throw new Error(
      "useDefinitionContext must be used within a DefinitionProvider",
    );
  }
  return context;
};
