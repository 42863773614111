import React, { Dispatch, SetStateAction } from "react";
import { Box, HStack } from "@chakra-ui/react";
import GameMenu from "../../components/GameMenu";
import CraftWordLogo from "../../components/CraftWordLogo";
import { LuHelpCircle } from "react-icons/lu";
import { IconButton } from "@chakra-ui/react";

type GamePageNavProps = {
  onLearnModalOpen: () => void;
  onAboutModalOpen: () => void;
  onGameArchiveOpen: () => void;
  onRareWordsDrawerOpen: () => void;
  onPlayerStatsDrawerOpen: () => void;
  onAuthModalOpen: () => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  resetGame: () => void;
  isSmallScreen: boolean;
  onOptionsModalOpen: () => void;
  userId: string | null;
  setUserId: Dispatch<SetStateAction<string | null>>;
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  emailLogin: string;
  setIsLogoutMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const GamePageNav: React.FC<GamePageNavProps> = ({
  onLearnModalOpen,
  onAboutModalOpen,
  onGameArchiveOpen,
  onRareWordsDrawerOpen,
  onPlayerStatsDrawerOpen,
  isPlaying,
  setIsPlaying,
  resetGame,
  isSmallScreen,
  onOptionsModalOpen,
  userId,
  setUserId,
  loggedIn,
  setLoggedIn,
  emailLogin,
  onAuthModalOpen,
  setIsLogoutMode,
}) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height={isSmallScreen ? "48px" : "64px"}
      zIndex="1000"
      bg="grayBrand.300"
    >
      <HStack w="100%" h="100%" px={4} justify="space-between" align="center">
        <CraftWordLogo />
        <Box flex="1" />
        <IconButton
          aria-label="Help"
          icon={<LuHelpCircle size="24px" />}
          variant="ghost"
          onClick={onLearnModalOpen}
          mr="-2"
        />
        <GameMenu
          onAboutModalOpen={onAboutModalOpen}
          onLearnModalOpen={onLearnModalOpen}
          onGameArchiveOpen={onGameArchiveOpen}
          onRareWordsDrawerOpen={onRareWordsDrawerOpen}
          onPlayerStatsDrawerOpen={onPlayerStatsDrawerOpen}
          onAuthModalOpen={onAuthModalOpen}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          resetGame={resetGame}
          onOptionsModalOpen={onOptionsModalOpen}
          userId={userId}
          setUserId={setUserId}
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          emailLogin={emailLogin}
          setIsLogoutMode={setIsLogoutMode}
        />
      </HStack>
    </Box>
  );
};

export default GamePageNav;
