import React, { useState, useEffect } from "react";
import { Flex, Icon, Text, keyframes } from "@chakra-ui/react";
import { LuAward } from "react-icons/lu";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

type CraftWordBestMessageProps = {
  bestPossibleLength: number | null;
};

const CraftWordBestMessage: React.FC<CraftWordBestMessageProps> = ({
  bestPossibleLength,
}) => {
  const [showFullSolutionText, setShowFullSolutionText] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationPhase, setAnimationPhase] = useState(0);

  useEffect(() => {
    setShowFullSolutionText(true);
    setIsAnimating(true);
    setAnimationPhase(0);

    const fadeOutTimer = setTimeout(() => {
      setAnimationPhase(1);
    }, 5000);

    const pauseTimer = setTimeout(() => {
      setShowFullSolutionText(false);
      setAnimationPhase(2);
    }, 5300);

    const fadeInTimer = setTimeout(() => {
      setAnimationPhase(3);
    }, 5600);

    const endAnimationTimer = setTimeout(() => {
      setIsAnimating(false);
    }, 5900);

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(pauseTimer);
      clearTimeout(fadeInTimer);
      clearTimeout(endAnimationTimer);
    };
  }, [bestPossibleLength]);

  return (
    <Flex
      alignItems="center"
      fontSize="sm"
      color="grayBrand.600"
      sx={{
        animation: isAnimating
          ? animationPhase === 0
            ? `${fadeIn} 0.3s ease-in`
            : animationPhase === 1
            ? `${fadeOut} 0.3s ease-out`
            : animationPhase === 2
            ? "none"
            : `${fadeIn} 0.3s ease-in`
          : "none",
        opacity: isAnimating
          ? animationPhase === 0 || animationPhase === 3
            ? 1
            : animationPhase === 1
            ? 0
            : animationPhase === 2
            ? 0
            : 1
          : 1,
      }}
    >
      <Icon as={LuAward} mr={1} fontSize="1em" />
      <Text>
        {showFullSolutionText ? "CW's Solution = " : "= "}
        {bestPossibleLength}
        {showFullSolutionText ? " moves" : ""}
      </Text>
    </Flex>
  );
};

export default CraftWordBestMessage;
