import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchSCOWL35k } from "./api/api";

type RareWordListContextType = {
  rareWordList: Set<string> | null;
};

const RareWordListContext = createContext<RareWordListContextType | undefined>(
  undefined,
);

export const RareWordListProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [rareWordList, setRareWordList] = useState<Set<string> | null>(null);

  useEffect(() => {
    const loadRareWordList = async () => {
      try {
        const words = await fetchSCOWL35k();
        if (words) {
          setRareWordList(new Set(words));
        } else {
          console.error("Could not load rare word list from server");
        }
      } catch (error) {
        console.error("Error loading rare word list:", error);
      }
    };

    void loadRareWordList();
  }, []);

  return (
    <RareWordListContext.Provider value={{ rareWordList }}>
      {children}
    </RareWordListContext.Provider>
  );
};

export const useRareWordList = (): RareWordListContextType => {
  const context = useContext(RareWordListContext);
  if (!context) {
    throw new Error(
      "useRareWordList must be used within a RareWordListProvider",
    );
  }
  return context;
};

export default RareWordListProvider;
