import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Box, Flex, Text, Button, Icon } from "@chakra-ui/react";
import {
  determineTransformation,
  displayDate,
  isRareWord,
  calculateRoundScore,
  getColorFromType,
} from "../utils/utils";
import { type RoundType } from "./types";
import { useRareWordList } from "../RareWordListContext";
import { StaticWordBlock } from "./Round/MoveTag";
import { LuAward, LuLifeBuoy, LuTrophy } from "react-icons/lu";
import RoundResultWrapped from "./RoundResultWrapped";
import { useArchiveDate } from "../ArchiveDateContext";

type GameRoundResultProps = {
  currentRound: RoundType;
  currentRoundIndex: number;
  advanceRound: () => void;
  isGameOver: boolean;
  setShowGameSummary: (value: boolean) => void;
  setDidGiveUp: Dispatch<SetStateAction<boolean>>;
};

const GameRoundResult: React.FC<GameRoundResultProps> = ({
  currentRound,
  currentRoundIndex,
  advanceRound,
  isGameOver,
  setShowGameSummary,
  setDidGiveUp,
}) => {
  const { archiveDate } = useArchiveDate(); // Use the context for archiveDate
  const [wordIndex, setWordIndex] = useState(0);
  const [transformationTypes, setTransformationTypes] = useState<string[]>([]);
  const [lastRevealedTagIndex, setLastRevealedTagIndex] = useState(-1);
  const [showSolution, setShowSolution] = useState(false);
  const { rareWordList } = useRareWordList();
  const [areTagsRendered, setAreTagsRendered] = useState(false);
  const currentDate = new Date();
  const roundScore = calculateRoundScore(currentRound);
  const [showRoundResultWrapped, setShowRoundResultWrapped] = useState(false);

  useEffect(() => {
    const allMovesAreX = currentRound.moves.every((move) => move.word === "X");
    if (allMovesAreX) {
      setDidGiveUp(true);
    }
  }, [currentRound, setDidGiveUp]);

  useEffect(() => {
    const types: string[] = [];
    const allMoves = [
      currentRound.startWord,
      ...currentRound.moves.map(({ word }) => word),
    ];
    for (let i = 0; i < allMoves.length - 1; i++) {
      const type = determineTransformation(allMoves[i], allMoves[i + 1]);
      types.push(type);
    }
    setTransformationTypes(types);
  }, [currentRound]);

  useEffect(() => {
    if (lastRevealedTagIndex < currentRound.moves.length - 1) {
      const timer = setTimeout(() => {
        setLastRevealedTagIndex(lastRevealedTagIndex + 1);
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [lastRevealedTagIndex, currentRound.moves.length]);

  useEffect(() => {
    if (wordIndex < currentRound.moves.length) {
      const timer = setTimeout(() => {
        setWordIndex(wordIndex + 1);
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [wordIndex, currentRound.moves.length]);

  useEffect(() => {
    if (lastRevealedTagIndex >= currentRound.moves.length - 1) {
      setAreTagsRendered(true);
    }
  }, [lastRevealedTagIndex, currentRound.moves.length]);

  const handleContinueClick = () => {
    setShowRoundResultWrapped(true);

    setTimeout(() => {
      if (isGameOver) {
        setShowGameSummary(true);
      } else {
        advanceRound();
      }
    }, 4000);
  };

  const isShorterThanBestPath =
    currentRound.bestPossibleLength !== null &&
    currentRound.moves.length < currentRound.bestPossibleLength;

  const [lastToggleTime, setLastToggleTime] = useState(Date.now());
  const [rapidToggle, setRapidToggle] = useState(false);
  const [solutionKey, setSolutionKey] = useState(0);

  const handleSolutionToggle = () => {
    const now = Date.now();
    if (now - lastToggleTime < 750) {
      setRapidToggle(true);
      setTimeout(() => {
        setRapidToggle(false);
      }, 3000);
    }
    setLastToggleTime(now);
    setShowSolution(!showSolution);
  };

  useEffect(() => {
    if (rapidToggle) {
      const timer = setTimeout(() => {
        setSolutionKey((prevKey) => prevKey + 1);
      }, 750);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [rapidToggle]);

  if (showRoundResultWrapped) {
    return (
      <Box
        w="100%"
        h="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <RoundResultWrapped
          currentDate={currentDate}
          bestPossibleScore={currentRound.bestPossibleLength}
          roundScore={currentRound.moves.length}
          isGameOver={isGameOver}
          advanceRound={advanceRound}
          setShowGameSummary={setShowGameSummary}
        />
      </Box>
    );
  }

  return (
    <Box
      w="100%"
      h="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Text textAlign="center" color="grayBrand.500">
        {displayDate(archiveDate ?? currentDate)}
      </Text>
      <Text textAlign="center" fontSize="xx-large" fontWeight="bold" mb="1">
        Round {currentRoundIndex + 1} Complete!
      </Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb={4}
        direction="column"
      >
        <Text fontWeight="bold" color="orangeBrand.500" fontSize="xx-large">
          {roundScore}
        </Text>
        {currentRound.isBestPathAchieved && (
          <Flex flexDirection="row" alignItems="center" fontSize="lg" mt="4">
            <Icon
              as={isShorterThanBestPath ? LuTrophy : LuAward}
              w={5}
              h={5}
              color="orangeBrand.500"
              strokeWidth="2"
              mr="-1"
            />
            <Text
              textAlign="center"
              color="grayBrand.900"
              w="280px"
              fontSize="md"
            >
              {isShorterThanBestPath
                ? "You beat CraftWord's solution!"
                : "You matched CraftWord's solution!"}
            </Text>
            <Icon
              as={isShorterThanBestPath ? LuTrophy : LuAward}
              w={5}
              h={5}
              color="orangeBrand.500"
              strokeWidth="2"
              ml="-1"
            />
          </Flex>
        )}
      </Flex>
      <Flex
        w="100%"
        justifyContent="center"
        gap={1}
        my={4}
        wrap="wrap"
        key={solutionKey}
      >
        <StaticWordBlock
          textColor="grayBrand.50"
          backgroundColor="grayBrand.500"
          word={currentRound.startWord}
          definitions={true}
        />
        {showSolution
          ? currentRound.bestPossibleWords
              .split(",")
              .map((solutionWord, index) => {
                if (index === 0) return null;
                return (
                  <StaticWordBlock
                    key={index}
                    word={solutionWord}
                    backgroundColor="grayBrand.100"
                    textColor="grayBrand.900"
                    isRare={isRareWord(solutionWord, rareWordList)}
                    definitions={true}
                  />
                );
              })
          : currentRound.moves.map((move, index) => {
              if (index <= lastRevealedTagIndex) {
                return (
                  <StaticWordBlock
                    key={move.id}
                    word={move.word}
                    textColor="grayBrand.50"
                    icon={undefined}
                    backgroundColor={
                      index <= lastRevealedTagIndex
                        ? getColorFromType(transformationTypes[index] || "")
                        : "grayBrand.100"
                    }
                    isRare={!!move && isRareWord(move.word, rareWordList)}
                    definitions={true}
                  />
                );
              } else {
                return null;
              }
            })}
      </Flex>
      <Flex
        justify="space-around"
        alignItems="center"
        mb="2"
        mt="4"
        fontSize="lg"
      >
        <Flex direction="column" width="100%"></Flex>
      </Flex>

      <Flex justifyContent="space-between">
        <Button
          variant="outline"
          color="grayBrand.500"
          borderColor="grayBrand.500"
          mr="2"
          onClick={handleSolutionToggle}
        >
          {showSolution ? (
            currentRound.isBestPathAchieved ? (
              <>
                <Icon
                  as={LuAward}
                  strokeWidth="2"
                  color="orangeBrand.500"
                  w={5}
                  h={5}
                />
                Show Your Solution
              </>
            ) : (
              "Show Your Solution"
            )
          ) : (
            <>
              <Icon as={LuAward} color="grayBrand.700" w={5} h={5} /> Show
              CraftWord Solution
            </>
          )}
        </Button>
        <Button colorScheme="orangeBrand" onClick={handleContinueClick}>
          Continue
        </Button>
      </Flex>
    </Box>
  );
};

export default GameRoundResult;
