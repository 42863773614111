import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  Flex,
  Collapse,
  Box,
} from "@chakra-ui/react";

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthModalOpen: () => void;
  isMobile: boolean;
  isStandalone: boolean;
  isLoggedIn: boolean;
}

const COOLDOWN_PERIOD = 60 * 60 * 1000; // 1 hour in milliseconds

const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  onClose,
  onAuthModalOpen,
  isMobile,
  isStandalone,
  isLoggedIn,
}) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [canShowModal, setCanShowModal] = useState(true);

  useEffect(() => {
    const lastInteractionTime = localStorage.getItem(
      "alertModalLastInteraction",
    );
    if (lastInteractionTime) {
      const timeSinceLastInteraction =
        Date.now() - parseInt(lastInteractionTime, 10);
      if (timeSinceLastInteraction < COOLDOWN_PERIOD) {
        setCanShowModal(false);
      }
    }
  }, []);

  const handleInteraction = () => {
    localStorage.setItem("alertModalLastInteraction", Date.now().toString());
    setCanShowModal(false);
  };

  const handleRegisterNowClick = () => {
    handleInteraction();
    onAuthModalOpen();
    onClose();
  };

  const handleDontShowAgainClick = () => {
    localStorage.setItem("dontShowAlertModal", "true");
    handleInteraction();
    onClose();
  };

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const hasContent =
  (!isLoggedIn || (isMobile && !isStandalone)) &&
  canShowModal &&
  !localStorage.getItem("dontShowAlertModal"); // Added check here

  useEffect(() => {
    if (isOpen && !hasContent) {
      onClose();
    }
  }, [isOpen, hasContent, onClose]);

  if (!hasContent) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Enjoying CraftWord?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="center">
            {!isLoggedIn && (
              <Box
                border="1.5px solid"
                borderRadius="8px"
                borderColor="grayBrand.400"
                padding="4"
                width="100%"
              >
                <Flex direction="column" align="center">
                  <Text
                    textAlign="center"
                    mb="2"
                    fontWeight="bold"
                    fontSize="lg"
                  >
                    Register
                  </Text>
                  <Text textAlign="center">
                    Login to keep track of your stats and play from any device.
                  </Text>
                  <Button
                    colorScheme="orangeBrand"
                    onClick={handleRegisterNowClick}
                    mt="4"
                  >
                    Register
                  </Button>
                </Flex>
              </Box>
            )}
            {isMobile && !isStandalone && (
              <Box
                border="1.5px solid"
                borderRadius="8px"
                borderColor="grayBrand.400"
                padding="4"
                width="100%"
              >
                <Flex direction="column" align="center">
                  <Text
                    fontSize="lg"
                    textAlign="center"
                    mb="2"
                    fontWeight="bold"
                  >
                    Add to Home Screen
                  </Text>
                  <Text textAlign="center" mb="2">
                    -Play in full-screen, distraction free
                  </Text>
                  <Text textAlign="center" mb="2">
                    -Never miss a day's challenge
                  </Text>
                  <Button onClick={toggleInstructions} size="sm">
                    {showInstructions ? "Hide Instructions" : "See How"}
                  </Button>
                  <Collapse in={showInstructions} animateOpacity>
                    <VStack align="start" spacing={2} mt="4">
                      <Text>
                        For Apple devices:
                        <br />
                        1. Open Safari and navigate to CraftWord
                        <br />
                        2. Tap the Share button
                        <br />
                        3. Scroll down and tap "Add to Home Screen"
                      </Text>
                      <Text>
                        For Android devices:
                        <br />
                        1. Open Chrome and navigate to CraftWord
                        <br />
                        2. Tap the menu button
                        <br />
                        3. Tap "Add to Home screen"
                      </Text>
                    </VStack>
                  </Collapse>
                </Flex>
              </Box>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} mr="auto">
            Cancel
          </Button>
          <Button
            variant="ghost"
            textColor="grayBrand.500"
            fontSize="xs"
            onClick={handleDontShowAgainClick}
          >
            Don't show this again
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
