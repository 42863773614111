import React from "react";
import { Icon } from "@chakra-ui/react";

const CraftWordLogo: React.FC<{ width?: string; height?: string }> = ({
  width = "66px",
  height = "32px",
}) => {
  return (
    <Icon viewBox="0 0 66 32" fill="none" width={width} height={height}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="66" height="32" viewBox="0 0 66 32" fill="none"> */}

      <path d="M0.5 0H33.1667V32H0.5V0Z" fill="#EC930D" />
      <path
        d="M20.4358 13.1879C20.2933 12.1484 20.0003 11.3183 19.5568 10.6977C19.1292 10.0771 18.5748 9.76679 17.8938 9.76679C16.9276 9.76679 16.1595 10.2167 15.5893 11.1166C15.0191 12.001 14.734 13.3741 14.734 15.2359C14.734 17.4856 15.1775 19.1302 16.0644 20.1697C16.9514 21.2092 18.0997 21.729 19.5093 21.729C20.1903 21.729 20.7922 21.6048 21.3149 21.3566C21.8375 21.1084 22.2097 20.8136 22.4315 20.4722C22.6215 20.6119 22.7799 20.8213 22.9066 21.1006C23.0333 21.3799 23.0967 21.6359 23.0967 21.8686C23.0967 22.2565 22.8908 22.6599 22.479 23.0788C22.083 23.4977 21.5049 23.8545 20.7447 24.1493C19.9844 24.4286 19.1054 24.5682 18.1076 24.5682C16.7138 24.5682 15.4388 24.2502 14.2826 23.6141C13.1422 22.9779 12.2315 22.016 11.5505 20.7282C10.8694 19.4405 10.5289 17.8812 10.5289 16.0504C10.5289 14.2662 10.8378 12.7535 11.4555 11.5122C12.0732 10.2555 12.9363 9.31685 14.045 8.69624C15.1696 8.06012 16.4762 7.74206 17.965 7.74206C18.3135 7.74206 18.7094 7.78861 19.1529 7.8817C19.5964 7.97479 19.9844 8.09891 20.317 8.25406C20.5546 8.14545 20.9031 8.03685 21.3624 7.92824C21.8217 7.81964 22.178 7.75758 22.4315 7.74206C22.8908 8.84364 23.1204 10.6589 23.1204 13.1879H20.4358Z"
        fill="white"
      />
      <path d="M33.1667 0H65.8333V32H33.1667V0Z" fill="#D9D9D9" />
      <path
        d="M61.1528 7.99806C61.3904 8.40145 61.5092 8.89018 61.5092 9.46424C60.9549 9.46424 60.5193 9.54958 60.2025 9.72024C59.9016 9.87539 59.6957 10.1547 59.5848 10.5581L56.5914 21.217C56.2746 22.3185 55.8707 23.1253 55.3798 23.6373C54.9046 24.1493 54.279 24.4053 53.5029 24.4053H52.1725L49.9868 16.9115L49.0602 13.4904L48.3 16.9348L47.0884 21.217C46.7716 22.3496 46.3915 23.1641 45.948 23.6606C45.5045 24.1571 44.8947 24.4053 44.1187 24.4053H42.8358L38.6307 10.5581C38.504 10.1392 38.3297 9.85212 38.108 9.69697C37.8863 9.54182 37.5141 9.46424 36.9914 9.46424C36.9914 8.87467 37.1102 8.38594 37.3478 7.99806H43.7623C44.0316 8.52557 44.1662 9.0143 44.1662 9.46424C43.6118 9.46424 43.2317 9.55733 43.0258 9.74352C42.8199 9.9297 42.717 10.1779 42.717 10.4882L44.7601 18.0053L45.1402 19.9137L47.3972 11.4192C47.5239 10.7675 47.7298 10.2245 48.0149 9.79006C48.3158 9.35564 48.696 9.13842 49.1553 9.13842H51.6498L54.0018 18.0053L54.5245 20.0068L55.0709 17.9588L56.829 11.2562C56.8765 11.0856 56.9002 10.9304 56.9002 10.7908C56.9002 10.3874 56.7498 10.0693 56.4488 9.83661C56.1479 9.58836 55.744 9.46424 55.2372 9.46424C55.2372 8.87467 55.3481 8.38594 55.5698 7.99806H61.1528Z"
        fill="#1B1B1B"
      />
    </Icon>
  );
};

export default CraftWordLogo;
