import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  Text,
  Tag,
  Flex,
  Box,
} from "@chakra-ui/react";

type TutorialModalProps = {
  isOpen: boolean;
  onClose: () => void;
  firstPlay: boolean;
  onTutorialComplete: () => void;
  startTutorial: () => void;
  openLearnModal: () => void;
};

type SpanProps = {
  color?: string;
  fontWeight?: string;
  children: React.ReactNode;
};

const TutorialModal: React.FC<TutorialModalProps> = ({
  isOpen,
  onClose,
  firstPlay,
  onTutorialComplete,
  startTutorial,
  openLearnModal,
}) => {
  const handleCompleteAndClose = () => {
    onTutorialComplete();
    onClose();
  };

  const handleStartTutorial = () => {
    startTutorial();
    onClose();
  };

  const Span: React.FC<SpanProps> = ({ children, ...props }) => (
    <Text as="span" fontWeight="bold" {...props}>
      {children}
    </Text>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent backgroundColor="grayBrand.50" color="grayBrand.900">
        <ModalHeader textAlign="center">
          <Text>How to play CraftWord</Text>
        </ModalHeader>
        <Text textAlign="center" fontSize="lg" mb={1} p={3}>
          <span>Get from </span>
          <Tag
            color="grayBrand.50"
            bgColor="grayBrand.500"
            px={2}
            borderRadius="0"
          >
            start
          </Tag>
          <span> to </span>
          <Tag
            as="span"
            color="grayBrand.50"
            bgColor="grayBrand.500"
            px={2}
            borderRadius="0"
          >
            goal
          </Tag>
          <span> in as few words as possible.</span>
        </Text>
        <Flex p={5} direction="column">
          <Text fontSize="lg" mb="2">
            To make new words:
          </Text>
          <Text fontSize="lg" mb="2" ml="2">
            -Add, remove, or change one letter at a time.
          </Text>
          <Text fontSize="lg" mb="4" ml="2">
            -Rearrange any letters.
          </Text>
          <Text fontSize="lg" mb="6">
            Examples:
          </Text>
          <Flex fontSize="lg" mb="2" ml="8">
            <Box flex="1">
              <Span color="greenBrand.500">add:</Span> <br />
              rats → rat
              <Span color="greenBrand.500">e</Span>s
            </Box>
            <Box flex="1">
              <Span color="blueBrand.400">remove:</Span> <br />
              fl
              <Span color="blueBrand.400">o</Span>at → flat
            </Box>
          </Flex>
          <Flex fontSize="lg" mb="2" ml="8">
            <Box flex="1">
              <Span color="orangeBrand.500">change:</Span> <br />
              rises → ris
              <Span color="orangeBrand.500">k</Span>s
            </Box>
            <Box flex="1">
              <Span color="purpleBrand.400">rearrange:</Span> <br />
              stale → st<Span color="purpleBrand.400">eal</Span>
            </Box>
          </Flex>
          <Text fontSize="lg" mb={2} mt="4">
            Scoring for each round:
          </Text>
          <Text fontSize="lg" mb={2} ml="2">
            -Your moves match CraftWord's solution = 0
          </Text>
          <Text fontSize="lg" mb={2} ml="2">
            -Each extra move = +1
          </Text>
          <Text fontSize="lg" mb={2} ml="2">
            -Each move below = -1
          </Text>
        </Flex>
        <ModalFooter justifyContent="center">
          <>
            <Button
              variant="outline"
              borderColor="gray.900"
              textColor="grayBrand.900"
              mb="4"
              mx="2"
              onClick={handleStartTutorial}
            >
              Play Tutorial
            </Button>
            <Button
              variant="ghost"
              textColor="grayBrand.500"
              size="sm"
              mb="4"
              onClick={handleCompleteAndClose}
            >
              Skip Tutorial
            </Button>
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TutorialModal;
