import React from "react";
import { Box, Fade, Alert, keyframes, type AlertProps } from "@chakra-ui/react";

const shake = keyframes`
0%, 100% {
  transform: translate3d(0, 0, 0);
}

25%, 75% {
  transform: translate3d(-3px, 0, 0);
}

50% {
  transform: translate3d(3px, 0, 0);
}
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeInOutAnimation = `${fadeIn} 0.3s ease-in, ${fadeOut} 0.3s ease-out 2s forwards`;
const shakeAnimation = `${shake} 1s ease-in-out, ${fadeOut} 0.3s ease-out 2s forwards`;

type AlertBoxProps = {
  keyString?: string | number;
  message: string;
  useFadeIn?: boolean;
  status?: AlertProps["status"];
  icon?: React.ReactNode;
  isAnimated?: boolean;
};

const AlertBox: React.FC<AlertBoxProps> = ({
  keyString,
  message,
  useFadeIn,
  status = "error",
  icon,
  isAnimated,
}) => {
  const animationStyle = useFadeIn ? fadeInOutAnimation : shakeAnimation;

  return (
    <Box width="auto" mx="1">
      <Fade in>
        <Alert
          key={keyString}
          bg="transparent"
          textAlign="center"
          status={status}
          p={2}
          fontSize="sm"
          sx={{
            animation: isAnimated ? animationStyle : "none",
          }}
        >
          {icon && (
            <span
              className="icon"
              style={{ marginRight: "0.5rem", marginLeft: "0.25rem" }}
            >
              {icon}
            </span>
          )}
          {message}
        </Alert>
      </Fade>
    </Box>
  );
};

export default AlertBox;
