import React, { useEffect, useState, useRef } from "react";
import {
  Flex,
  IconButton,
  Tooltip,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import RoundMoves from "./RoundMoves";
import AlertBox from "./AlertBox";
import CraftWordBestMessage from "../CraftWordBestMessage";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./rounddisplay.css";

import {
  LuArrowLeft,
  LuArrowRight,
  LuAward,
  LuBookX,
  LuBan,
  LuRotateCcw,
  LuLifeBuoy,
  LuFlag,
} from "react-icons/lu";
import { BookHeart } from "lucide-react";
import { Move } from "../types";

type RoundDisplayProps = {
  wordPair: string[];
  onSubmitWord: (userInput: string, clearInput: () => void) => void;
  pastMoves: Move[];
  invalidWordError: string | null;
  invalidMoveError: string | null;
  rareWordAlert: string | null;
  craftwordBestAlert: string | null;
  showInvalidWordError: boolean;
  showInvalidMoveError: boolean;
  showRareWordAlert: boolean;
  showCraftwordBestAlert: boolean;
  setShowInvalidWordError: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInvalidMoveError: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRareWordAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCraftwordBestAlert: React.Dispatch<React.SetStateAction<boolean>>;
  craftwordsBestPathLength: number | null;
  errorCount: number;
  undoMove: () => void;
  undoBackwardsMove: () => void;
  clearMoves: () => void;
  handleShowCraftwordBestAlert: () => void;
  rareWordsUsed: string[];
  isRoundOver: boolean;
  isMobile: boolean;
  firstPlay: boolean;
  useVirtualKeyBoard: boolean;
  onHintRequested?: () => void;
  onGiveUpRequested?: () => void;
  allHintsUsed?: boolean;
  showRightArrow?: boolean;
  showLeftArrow?: boolean;
  showStartOver?: boolean;
  showHelpIcon?: boolean;
  currentPosition?: number | undefined;
};

const RoundDisplay: React.FC<RoundDisplayProps> = ({
  wordPair,
  onSubmitWord,
  pastMoves,
  invalidWordError,
  invalidMoveError,
  rareWordAlert,
  craftwordBestAlert,
  showInvalidWordError,
  showInvalidMoveError,
  showRareWordAlert,
  showCraftwordBestAlert,
  setShowInvalidWordError,
  setShowInvalidMoveError,
  setShowRareWordAlert,
  setShowCraftwordBestAlert,
  handleShowCraftwordBestAlert,
  errorCount,
  undoMove,
  undoBackwardsMove,
  clearMoves,
  rareWordsUsed,
  isRoundOver,
  isMobile,
  firstPlay,
  craftwordsBestPathLength,
  useVirtualKeyBoard,
  onGiveUpRequested,
  onHintRequested,
  allHintsUsed,
  showRightArrow,
  showLeftArrow,
  showStartOver,
  showHelpIcon,
  currentPosition,
}) => {
  const [userInput, setUserInput] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [keyboardPosition, setKeyboardPosition] = useState("8vh");
  useEffect(() => {
    const updateKeyboardPosition = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight > 800) {
        setKeyboardPosition("8vh");
      } else if (windowHeight <= 800 && windowHeight > 750) {
        setKeyboardPosition("4vh");
      } else {
        setKeyboardPosition("0vh");
      }
    };
    updateKeyboardPosition();
    window.addEventListener("resize", updateKeyboardPosition);
    return () => window.removeEventListener("resize", updateKeyboardPosition);
  }, []);

  const [backspaceCount, setBackspaceCount] = useState(0);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmitWord(userInput, () => {
        setUserInput("");
      });
    } else if (
      (e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft") &&
      userInput === ""
    ) {
      if (backspaceCount === 0) {
        setTimeout(() => setBackspaceCount(0), 500);
        setBackspaceCount(1);
      } else if (backspaceCount === 1) {
        undoMove();
        setBackspaceCount(0);
      }
    }
  };

  const handleButtonClick = () => {
    onSubmitWord(userInput, () => {
      setUserInput("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lowercaseInput = e.target.value.toLowerCase();
    if (!lowercaseInput.includes(" ")) {
      setUserInput(lowercaseInput);
    }
  };

  const handleVirtualKeyPress = (button: string) => {
    if (button === "{enter}") {
      onSubmitWord(userInput, () => setUserInput(""));
    } else if (button === "{backspace}") {
      if (userInput === "") {
        if (backspaceCount === 0) {
          setTimeout(() => setBackspaceCount(0), 500);
          setBackspaceCount(1);
        } else if (backspaceCount === 1) {
          undoMove();
          setBackspaceCount(0);
        }
      } else {
        setUserInput((currentInput) => currentInput.slice(0, -1));
        setBackspaceCount(0);
      }
    } else if (button === "{startOver}") {
      clearMoves();
    } else if (button === "{showCWBest}") {
      handleShowCraftwordBestAlert();
    } else if (button === "{undo}") {
      undoMove();
    } else {
      const lowercaseButton = button.toLowerCase();
      setUserInput((currentInput) => currentInput + lowercaseButton);
      setBackspaceCount(0);
    }
  };

  useEffect(() => {
    if (invalidWordError) {
      const timeoutId = setTimeout(() => {
        setShowInvalidWordError(false);
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [invalidWordError, setShowInvalidWordError]);

  useEffect(() => {
    if (invalidMoveError) {
      const timeoutId = setTimeout(() => {
        setShowInvalidMoveError(false);
      }, 4000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [invalidMoveError, setShowInvalidMoveError]);

  useEffect(() => {
    if (rareWordAlert) {
      const timeoutId = setTimeout(() => {
        setShowRareWordAlert(false);
      }, 4000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [rareWordAlert, setShowRareWordAlert]);

  useEffect(() => {
    if (craftwordBestAlert) {
      const timeoutId = setTimeout(() => {
        setShowCraftwordBestAlert(false);
      }, 4000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [craftwordBestAlert, setShowCraftwordBestAlert]);

  const [isGiveUpConfirmation, setIsGiveUpConfirmation] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <Flex direction="column" alignItems="center" width="100%">
      <Box w="100%" p={3}>
        <RoundMoves
          moves={pastMoves}
          start={wordPair[0]}
          goal={wordPair[1]}
          rareWordsUsed={rareWordsUsed}
          userInput={userInput}
          onSubmitWord={onSubmitWord}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          isRoundOver={isRoundOver}
          inputRef={inputRef}
          isMobile={isMobile}
          useVirtualKeyBoard={useVirtualKeyBoard}
          currentPosition={currentPosition}
        />
      </Box>
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Flex direction="row" alignItems="center" flex={1}>
          {(showLeftArrow || !firstPlay) && (
            <Tooltip label="undo last move">
              <IconButton
                aria-label="undo button"
                icon={<LuArrowLeft fontSize="22px" />}
                onClick={undoMove}
                variant="ghost"
                colorScheme="grayBrand"
                isDisabled={pastMoves.length === 0 || isRoundOver}
              />
            </Tooltip>
          )}
          {(showStartOver || !firstPlay) && (
            <Tooltip label="start over">
              <IconButton
                aria-label="start over"
                icon={<LuRotateCcw fontSize="20px" />}
                onClick={clearMoves}
                variant="ghost"
                colorScheme="grayBrand"
                isDisabled={pastMoves.length === 0 || isRoundOver}
              />
            </Tooltip>
          )}
        </Flex>

        {!firstPlay && (
          <Flex justifyContent="center" alignItems="center">
            <CraftWordBestMessage
              bestPossibleLength={craftwordsBestPathLength}
            />
          </Flex>
        )}

        <Flex
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flex={1}
        >
          {(showHelpIcon || !firstPlay) && (
            <Menu>
              <Tooltip label="help menu">
                <MenuButton
                  as={IconButton}
                  aria-label="Help Menu"
                  icon={<LuLifeBuoy fontSize="20px" />}
                  variant="ghost"
                  colorScheme="grayBrand"
                  isDisabled={firstPlay || isRoundOver}
                />
              </Tooltip>
              <MenuList borderRadius="0" width="auto">
                <MenuItem isDisabled={allHintsUsed} onClick={onHintRequested}>
                  <Flex alignItems="center">
                    <Icon as={LuLifeBuoy} mr={2} />
                    Show Hint
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => setIsGiveUpConfirmation(true)}>
                  <Flex alignItems="center">
                    <Icon as={LuFlag} mr={2} />
                    Give Up
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          <AlertDialog
            isOpen={isGiveUpConfirmation}
            onClose={() => setIsGiveUpConfirmation(false)}
            leastDestructiveRef={cancelRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Give Up
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to give up?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={() => setIsGiveUpConfirmation(false)}
                  >
                    No
                  </Button>
                  <Button
                    colorScheme="orangeBrand"
                    onClick={() => {
                      onGiveUpRequested?.();
                      setIsGiveUpConfirmation(false);
                    }}
                    ml={3}
                  >
                    Yes
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          {(showRightArrow || !firstPlay) && (
            <IconButton
              aria-label="undo move ahead"
              icon={<LuArrowRight fontSize="22px" />}
              onClick={undoBackwardsMove}
              variant="ghost"
              colorScheme="grayBrand"
              isDisabled={
                isRoundOver ||
                currentPosition === undefined ||
                currentPosition >= pastMoves.length
              }
            />
          )}
        </Flex>
      </Flex>
      <Box minH="60px">
        <Flex direction="row" justifyContent="center" mt={4}>
          {showInvalidWordError && invalidWordError && (
            <AlertBox
              keyString={`invalid-word-${errorCount}`}
              message={invalidWordError}
              isAnimated={showInvalidWordError}
              useFadeIn={false}
              icon={<LuBookX size={18} />}
            />
          )}
          {showInvalidMoveError && invalidMoveError && (
            <AlertBox
              keyString={`invalid-move-${errorCount}`}
              useFadeIn={false}
              message={invalidMoveError}
              isAnimated={showInvalidMoveError}
              icon={<LuBan size={18} />}
            />
          )}
          {showRareWordAlert && rareWordAlert && (
            <AlertBox
              keyString={`rare-word-${errorCount}`}
              message={rareWordAlert}
              isAnimated={showRareWordAlert}
              useFadeIn={true}
              icon={<BookHeart size={20} />}
            />
          )}
          {showCraftwordBestAlert && craftwordBestAlert && (
            <AlertBox
              keyString={`craftword-best-${errorCount}`}
              message={craftwordBestAlert}
              isAnimated={showCraftwordBestAlert}
              status="info"
              useFadeIn={true}
              icon={<LuAward size={18} />}
            />
          )}
        </Flex>
      </Box>
      {useVirtualKeyBoard && (
        <Box
          position="fixed"
          bottom={keyboardPosition}
          width="100%"
          maxW={!isMobile ? "900px" : "none"}
          p={2}
          marginLeft="auto"
          marginRight="auto"
          backgroundColor="grayBrand.50"
          zIndex="10"
        >
          <Keyboard
            width={!isMobile ? "900px" : "100%"}
            onKeyPress={handleVirtualKeyPress}
            layout={{
              default: [
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "{enter} z x c v b n m {backspace}",
              ],
            }}
            buttonTheme={[
              {
                class: "backspaceKey",
                buttons: "{backspace}",
              },
              {
                class: "enterKey",
                buttons: "{enter}",
              },
              {
                class: "undoKey",
                buttons: "{undo}",
              },
              {
                class: "startOverKey",
                buttons: "{startOver}",
              },
              {
                class: "showCWBestKey",
                buttons: "{showCWBest}",
              },
            ]}
            display={{
              "{startOver}": "⟲",
              "{showCWBest}": " ",
              "{enter}": "ENTER",
              "{backspace}": "⌫",
              "{undo}": "UNDO",
            }}
          />
        </Box>
      )}
    </Flex>
  );
};

export default RoundDisplay;
